<template>
  <v-dialog
    fullscreen
    persistent
    style="background-color: white"
    @input="$emit('input')"
    :value="value"
  >
    <v-card>
      <v-toolbar dark color="#3d406c">
        <v-btn icon @click="$emit('input')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("resetPassword.title") }}</v-toolbar-title>
      </v-toolbar>

      <div style="background-color: white" class="d-flex flex-column px-8 mt-4">
        <img class="w-28" :src="logo" />
        <div class="mt-2" style="font-size: 1.3rem">{{ $t("resetPassword.title") }}</div>
        <div class="" style="color: #737373; font-size: 0.9rem">
          {{ $t("resetPassword.subtitle") }}
        </div>
        <input
          v-model="email"
          type="email"
          :placeholder="$t('resetPassword.email')"
          class="mt-4 textfield-input"
        />
        <input
          v-model="passport"
          type="text"
          :placeholder="$t('resetPassword.passport')"
          class="mt-4 textfield-input"
          autocapitalize="off"
        />

        <v-alert
          class="my-2"
          dense
          v-if="errorMessage !== ''"
          elevation="3"
          type="error"
          >{{ errorMessage }}</v-alert
        >
        <button class="button-login mt-4" @click="submitButton">
          {{ $t("resetPassword.submit") }}
        </button>
      </div>
    </v-card>
    <v-overlay :value="isLoading">
      <v-progress-circular color="blue darken-4" indeterminate size="40"></v-progress-circular>
    </v-overlay>
    <v-overlay :dark="false" :value="reset">
      <v-card>
        <v-card-text>
          <div class="d-flex flex-column" style="background-color: white">
            <v-tabs-items v-model="step">
              <v-tab-item>
                <div class="w-full">
                  <div class="text-2xl">{{ $t("resetPassword.title") }}</div>
                  <div class="text-sm mt-2">
                    {{ $t("resetPassword.resetDescription") }}
                  </div>
                  <v-otp-input
                    v-model="verifyCode"
                    class="mt-4"
                    length="6"
                    type="number"
                  ></v-otp-input>
                  <v-alert
                    class="my-2"
                    dense
                    v-if="errorMessage1 !== ''"
                    elevation="3"
                    type="error"
                    >{{ errorMessage1 }}</v-alert
                  >
                  <v-btn
                    block
                    @click="nextButton"
                    class="mt-4"
                    dark
                    large
                    color="blue-grey darken-4"
                    >{{ $t("resetPassword.next") }}</v-btn
                  >
                </div>
              </v-tab-item>
              <v-tab-item>
                <input
                  v-model="newPassword"
                  type="password"
                  :placeholder="$t('resetPassword.newPassword')"
                  class="mt-4 textfield-input w-full"
                  autocapitalize="off"
                />

                <input
                  v-model="confirmPassword"
                  type="password"
                  :placeholder="$t('resetPassword.confirmPassword')"
                  class="mt-4 textfield-input w-full"
                  autocapitalize="off"
                />
                <v-alert
                  class="my-2"
                  dense
                  v-if="errorMessage2 !== ''"
                  elevation="3"
                  type="error"
                  >{{ errorMessage2 }}</v-alert
                >
                <v-btn
                  block
                  @click="resetButton"
                  class="mt-4"
                  dark
                  large
                  color="blue-grey darken-4"
                  >{{ $t("resetPassword.reset") }}</v-btn
                >
              </v-tab-item>
              <v-tab-item>
                <div style="color: black" class="p-2 d-flex flex-column">
                  <div class="text-3xl mx-auto">{{ $t("resetPassword.success") }}</div>
                  <img class="mx-auto my-2 w-24" :src="successImage" />
                  <div class="text-xl mt-2 mx-auto">
                    {{ $t("resetPassword.successMessage") }}
                  </div>

                  <v-btn
                    dark
                    @click="$emit('input')"
                    class="mt-4"
                    large
                    color="blue-grey darken-4"
                    >{{ $t("common.ok") }}</v-btn
                  >
                </div></v-tab-item
              >
            </v-tabs-items>
          </div>
        </v-card-text>
      </v-card>
    </v-overlay>
  </v-dialog>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      email: "",
      passport: "",
      verifyCode: "",
      newPassword: "",
      confirmPassword: "",
      reset: false,
      step: 0,
      isLoading: false,
      success: false,
      errorMessage: "",
      errorMessage1: "",
      errorMessage2: "",
      logo: require("../../assets/logo/logo-1.png"),
      leftArrowIcon: require("../../assets/icon/arrow-left.png"),
      successImage: require("../../assets/check.png"),
    };
  },
  props: ["value"],
  components: {},
  computed: {},
  methods: {
    submitButton() {
      if (!/.+@.+\..+/.test(this.email)) {
        this.errorMessage = this.$t("resetPassword.wrongEmail");
      } else if (this.passport === "") {
        this.errorMessage = this.$t("resetPassword.emptyPassport");
      } else {
        this.isLoading = true;
        axios({
          method: "POST",
          url: process.env.VUE_APP_BACKENDAPI + "auth/resetPassword",
          data: {
            Email: this.email,
            Passport: this.passport,
          },
        }).then(
          (response) => {
            this.isLoading = false;

            if (response.data.status) {
              this.reset = true;
            } else {
              this.errorMessage = this.$t("resetPassword.noRecord");
            }
          },
          (error) => {
            console.log(error);
            this.isLoading = false;
          }
        );
      }
    },
    nextButton() {
      if (this.verifyCode.length === 6) {
        this.step = 1;
      } else {
        this.errorMessage1 = this.$t("resetPassword.emptyCode");
      }
    },
    resetButton() {
      if (this.newPassword.length < 8) {
        this.errorMessage2 = this.$t("resetPassword.tooShortPassword");
      } else if (this.newPassword !== this.confirmPassword) {
        this.errorMessage2 = this.$t("resetPassword.passwordNotMatch");
      } else {
        this.isLoading = true;
        axios({
          method: "POST",
          url: process.env.VUE_APP_BACKENDAPI + "auth/resetPasswordVerify",
          data: {
            Email: this.email,
            code: this.verifyCode,
            newPassword: this.newPassword,
          },
        }).then((response) => {
          this.isLoading = false;
          if (response.data.status) {
            this.step = 2;
          } else {
            this.errorMessage2 = this.$t("resetPassword.wrongVerifyCode");
          }
        });
      }
    },
  },
  created() {},
  mounted() {},
};
</script>

<style scoped>
.textfield-input {
  color: #000000;
  border-style: solid;
  border-width: 1px;
  border-color: #8c8c8c;
  border-radius: 0.7rem;

  padding: 0.6rem;
  font-size: 1.2rem;
}
.upload-container {
  color: #000000;
  border-style: solid;
  border-width: 1px;
  border-color: #8c8c8c;
  border-radius: 0.7rem;
  height: 10rem;
  padding: 0.6rem;
  font-size: 1.2rem;
}
.button-login {
  font-size: 1.2rem;
  background-color: #3d406c;
  color: #ffffff;
  padding: 0.4rem;
  border-radius: 0.75rem;
}
input[type="file"] {
  display: none;
}
</style>
