<template>
  <div class="container-trade d-flex flex-column">
    <div>
      <div style="background-color: #283593" class="d-flex mb-3 px-3 py-3">
        <div
          class="my-auto"
          style="width: 30px; background-color: white; border-radius: 30px"
        >
          <img style="width: 30px" :src="coinInfo[tradeSymbolGetter].img" />
        </div>
        <div class="my-auto ml-4" style="font-size: 18px; color: #ffffff">
          {{ tradeSymbolGetter }}
        </div>
        <div
          :style="{
            fontSize: '20px',
            color: getCurrentCoin.change_pct_24h < 0 ? '#ffffff' : '#ffffff',
          }"
          class="ml-2 my-auto"
        >
          {{ getCurrentCoin.current_price }}
        </div>
        <div
          :style="{
            fontSize: '16px',
            color: getCurrentCoin.change_pct_24h < 0 ? '#f7445d' : '#30bb87',
          }"
          class="ml-2 my-auto"
        >
          {{ getCurrentCoin.change_pct_24h }}%
        </div>
      </div>
      <div class="px-3">
        <div class="mt-3" style="color: #616161; font-size: 14px">Trading pair</div>
        <div style="text-align: center" class="w-full flex-grow-0 trade-value-container">
          {{ getCurrentCoin.symbol_head }}{{ getCurrentCoin.symbol_end }}
        </div>
      </div>
      <div class="d-flex mt-3">
        <div
          class="d-flex flex-column px-3 py-4"
          style="
            border-radius: 0 10px 10px 0px;
            color: #f5f5f5;
            width: 47%;
            background-color: #424242;
          "
        >
          <div style="font-size: 20px; font-weight: bold">
            {{ $t("price.coinDetail.buy") }}
          </div>

          <div style="color: #30bb87">
            {{ ((buySell[3] * 100) / (buySell[3] * 1 + buySell[2] * 1)).toFixed(1)
            }}{{ "%" }}
          </div>
          <div
            :style="{
              width:
                Math.floor((buySell[3] * 100) / (buySell[3] * 1 + buySell[2] * 1)) + '%',
            }"
            style="background-color: #30bb87; height: 4px; transition: width 0.3s"
            class="my-auto"
          ></div>
          <div
            style="
              background-color: #069b40;
              width: 100%;
              color: #ffffff;
              font-size: 13px;
            "
            class="pa-2 mt-1 d-flex"
          >
            <div>
              {{ getCurrentCoin.symbol_head }}
            </div>
            <div class="ml-2">${{ buySell[1] }}</div>
            <div class="ml-auto">
              {{ buySell[3] }}
            </div>
          </div>
          <div class="mt-4" style="font-size: 20px; font-weight: bold">
            {{ $t("price.coinDetail.sell") }}
          </div>
          <div style="color: #f7445d">
            {{ ((buySell[2] * 100) / (buySell[3] * 1 + buySell[2] * 1)).toFixed(1)
            }}{{ "%" }}
          </div>
          <div
            :style="{
              width:
                Math.floor(((buySell[2] * 100) / (buySell[3] * 1 + buySell[2] * 1)) * 1) +
                '%',
            }"
            style="background-color: #f7445d; height: 4px; transition: width 0.3s"
            class="my-auto"
          ></div>
          <div
            style="
              background-color: #db2020;
              width: 100%;
              color: #ffffff;
              font-size: 13px;
            "
            class="pa-2 mt-1 d-flex"
          >
            <div>
              {{ getCurrentCoin.symbol_head }}
            </div>
            <div class="ml-2">${{ buySell[0] }}</div>
            <div class="ml-auto">
              {{ buySell[2] }}
            </div>
          </div>
        </div>
        <div style="width: 53%" class="pl-3 pr-3 d-flex flex-column">
          <!--  <div class="w-full trade-value-container">
            {{ tradeSymbolGetter }}
          </div> -->
          <div style="color: #616161; font-size: 14px">Target price</div>
          <div class="w-full trade-value-container d-flex">
            <input
              :value="targetPrice"
              :placeholder="$t('trade.setPrice')"
              class="mx-auto"
              style="text-align: center; width: 100%"
              type="text"
              inputmode="decimal"
              @input="onPriceInput"
            />
            <div style="position: absolute; right: 5px">
              <img @click="setTargetPrice" style="width: 1.3rem" :src="setPriceIcon" />
            </div>
          </div>

          <div class="mt-3" style="color: #616161; font-size: 14px">Amount</div>
          <div class="w-full trade-value-container d-flex" style="position: relative">
            <div style="position: absolute; left: 5px">
              <img @click="minusButton" style="width: 1.3rem" :src="minusIcon" />
            </div>
            <input
              :value="amount"
              :placeholder="$t('trade.amount')"
              style="text-align: center; width: 100%"
              type="text"
              inputmode="decimal"
              @input="onAmountInput"
            />
            <div style="position: absolute; right: 5px">
              <img @click="plusButton" style="width: 1.3rem" :src="plusIcon" />
            </div>
          </div>
          <div class="mt-3" style="color: #616161; font-size: 14px">Total (USDT)</div>
          <div class="w-full trade-value-container d-flex">
            <input
              :value="totalUSDT"
              :placeholder="$t('trade.totalUSDT')"
              class="mx-auto"
              style="text-align: center"
              type="text"
              inputmode="decimal"
              @input="onTotalUSDTInput"
            />
          </div>
        </div>
      </div>
      <div class="mt-2 px-3 mb-5 d-flex">
        <div style="width: 50%" class="pr-2">
          <div class="my-auto flex-shrink-1" style="color: #616161; font-size: 14px">
            {{ $t("trade.avblBuy") }}
          </div>
          <div class="w-full flex-grow-0 trade-value-container d-flex">
            <input
              :value="AvblBuy"
              placeholder="Avbl (buy)"
              style="text-align: center; width: 100%"
              type="text"
              inputmode="decimal"
              disabled
            />
          </div>
        </div>
        <div style="width: 50%" class="pl-2">
          <div class="my-auto flex-shrink-1" style="color: #616161; font-size: 14px">
            {{ $t("trade.avblSell") }}
          </div>
          <div class="w-full flex-grow-0 trade-value-container d-flex">
            <input
              :value="AvblSell"
              placeholder="Avbl (sell)"
              style="text-align: center; width: 100%"
              type="text"
              inputmode="decimal"
              disabled
            />
          </div>
        </div>
      </div>

      <!--  
        
      
      </v-row> -->
    </div>

    <div class="mt-auto mb-4 px-3">
      <v-btn large block dark color="#30bb87" @click="buyButton">
        {{ $t("trade.buy") }}</v-btn
      >
      <v-btn class="mt-4" large block dark color="#f7445d" @click="sellButton">
        {{ $t("trade.sell") }}</v-btn
      >
    </div>
    <SelectCoin v-if="dialog.selectCoin" v-model="dialog.selectCoin" />
  </div>
</template>
<script>
import coinInfo from "../../utils/coinInfo";
import axios from "../../axios";
import SelectCoin from "./selectCoin.vue";
import { mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      dialog: {
        selectCoin: false,
      },
      targetPrice: "",
      amount: "",
      totalUSDT: "",
      setPriceIcon: require("../../assets/icon/crosshairs-gps.png"),
      minusIcon: require("../../assets/icon/minus.png"),
      plusIcon: require("../../assets/icon/plus.png"),
      buySell: [0, 0, 0, 0],
      intervalWorker: 0,
    };
  },
  components: { SelectCoin },
  computed: {
    coinInfo() {
      return coinInfo;
    },
    ...mapGetters(["userDataGetter", "tradeSymbolGetter", "coinDataGetter"]),
    getCurrentCoin() {
      return this.coinDataGetter.find(
        (element) => element.coin_symbol === this.tradeSymbolGetter
      );
    },

    AvblBuy() {
      if (this.targetPrice === "" || this.targetPrice <= 0) {
        return 0;
      } else {
        let usdt = this.userDataGetter.user_coin.find(
          (element) => element.coin_symbol == "USDT"
        );
        let avblUSDT = usdt.amount - usdt.reserved;
        return Math.floor((avblUSDT * 100000) / this.targetPrice) / 100000;
      }
    },
    AvblSell() {
      let coin = this.userDataGetter.user_coin.find(
        (element) => element.coin_symbol === this.tradeSymbolGetter
      );
      return coin.amount - coin.reserved;
    },
  },
  methods: {
    ...mapMutations(["snackbarSetter"]),
    buySellSetup() {
      let sellSide = this.getCurrentCoin.current_price * (1 + Math.random() * 0.002);
      let buySide = this.getCurrentCoin.current_price * (1 - Math.random() * 0.002);
      let sellSideVol =
        (5000 * (0.5 + Math.random())) / this.getCurrentCoin.current_price;
      let buySideVol = (5000 * (0.5 + Math.random())) / this.getCurrentCoin.current_price;
      if (sellSideVol >= 1) {
        sellSideVol = Math.floor(sellSideVol);
      } else {
        {
          sellSideVol = sellSideVol.toPrecision(3);
        }
      }
      if (buySideVol >= 1) {
        buySideVol = Math.floor(buySideVol);
      } else {
        {
          buySideVol = buySideVol.toPrecision(3);
        }
      }
      if (this.getCurrentCoin.current_price < 1) {
        sellSide = sellSide.toPrecision(4);
        buySide = buySide.toPrecision(4);
      } else {
        sellSide = sellSide.toFixed(2);
        buySide = buySide.toFixed(2);
      }
      console.log(sellSide);
      this.buySell = [sellSide, buySide, sellSideVol, buySideVol];
      console.log(this.buySell);
    },
    buyButton() {
      if (this.targetPrice == "" || this.targetPrice * 1 <= 0) {
        this.snackbarSetter({
          success: false,
          message: "Please enter price",
          visible: true,
        });
      } else if (this.amount == "" || this.amount * 1 <= 0) {
        this.snackbarSetter({
          success: false,
          message: "Please enter amount",
          visible: true,
        });
      } else if (this.targetPrice / this.getCurrentCoin.current_price > 1.02) {
        this.snackbarSetter({
          success: false,
          message: "Price are too high",
          visible: true,
        });
      } else if (this.amount * 1 > this.AvblBuy * 1) {
        this.snackbarSetter({
          success: false,
          message: "Not enough USDT",
          visible: true,
        });
      } else {
        axios
          .post("/order/create", {
            target_price: this.targetPrice,
            coin_symbol: this.tradeSymbolGetter,
            amount: this.amount,
            type: "buy",
          })
          .then(
            (response) => {
              console.log(response.data);
              if (response.data.status) {
                this.amount = "";
                this.targetPrice = "";
                this.snackbarSetter({
                  success: true,
                  message: "Order success",
                  visible: true,
                });
              } else {
                this.snackbarSetter({
                  success: false,
                  message: "Order fail",
                  visible: true,
                });
              }
            },
            (error) => {
              console.log(error);
            }
          );
      }
    },
    sellButton() {
      if (this.targetPrice == "" || this.targetPrice * 1 <= 0) {
        this.snackbarSetter({
          success: false,
          message: "Please enter price",
          visible: true,
        });
      } else if (this.amount == "" || this.amount * 1 <= 0) {
        this.snackbarSetter({
          success: false,
          message: "Please enter amount",
          visible: true,
        });
      } else if (this.getCurrentCoin.current_price / this.targetPrice > 1.02) {
        this.snackbarSetter({
          success: false,
          message: "Price are too low",
          visible: true,
        });
      } else if (this.amount * 1 > this.AvblSell * 1) {
        this.snackbarSetter({
          success: false,
          message: "Not enough amount",
          visible: true,
        });
      } else {
        //  let symbol = this.getCurrentCoin.s.substring(0, this.getCurrentCoin.s.length - 4);
        axios
          .post("/order/create", {
            target_price: this.targetPrice,
            coin_symbol: this.tradeSymbolGetter,
            amount: this.amount,
            type: "sell",
          })
          .then(
            (response) => {
              if (response.data.status) {
                this.amount = "";
                this.targetPrice = "";
                this.snackbarSetter({
                  success: true,
                  message: "Order success",
                  visible: true,
                });
              } else {
                this.snackbarSetter({
                  success: false,
                  message: "Order fail",
                  visible: true,
                });
              }
            },
            (error) => {
              console.log(error);
            }
          );
      }
    },
    onPriceInput(e) {
      if (isNaN(e.target.value) || e.target.value < 0) {
        e.preventDefault();
      } else {
        this.targetPrice = e.target.value;
        this.calculateTotal();
      }
    },
    onAmountInput(e) {
      if (isNaN(e.target.value) || e.target.value < 0) {
        e.preventDefault();
      } else {
        this.amount = e.target.value;
        this.calculateTotal();
      }
    },
    onTotalUSDTInput(e) {
      if (isNaN(e.target.value) || e.target.value < 0) {
        e.preventDefault();
      } else {
        if (!isNaN(this.targetPrice)) {
          this.amount = e.target.value / this.targetPrice;
          this.totalUSDT = e.target.value;
        }
      }
    },
    calculateTotal() {
      if (!isNaN(this.targetPrice) && !isNaN(this.amount)) {
        this.totalUSDT = this.targetPrice * this.amount;
      }
    },
    setTargetPrice() {
      this.targetPrice = this.getCurrentCoin.current_price;
    },
    minusButton() {
      if (this.amount == "") {
        this.amount = 0.1;
      } else {
        this.amount = this.amount * 1 - 0.1;
      }
      this.calculateTotal();
    },
    plusButton() {
      if (this.amount == "") {
        this.amount = 0.1;
      } else {
        this.amount = this.amount * 1 + 0.1;
      }
      this.calculateTotal();
    },
  },
  created() {},
  mounted() {
    this.buySellSetup();
    this.intervalWorker = setInterval(this.buySellSetup, 2000);
  },
  beforeDestroy() {
    clearInterval(this.intervalWorker);
  },
};
</script>

<style scoped>
.container-trade {
  background: white;
  height: 100%;
}
.trade-value-container {
  position: relative;
  border-radius: 0.3rem;
  text-align: center;
  background-color: #f5f5f5;
  color: #737373;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
textarea:focus,
input:focus {
  outline: none;
}
input:disabled {
  -webkit-text-fill-color: #000000;
}
.text-price-up {
  color: #30bb87;
}
.text-price-down {
  color: #f7445d;
}
</style>
