<template>
  <div style="position: relative; height: 100%; width: 100%">
    <div
      style="position: absolute; top: 0; bottom: 0; left: 0; right: 0; overflow-y: scroll"
    >
      <Referral :referralCode="referralCode" />
    </div>
  </div>
</template>

<script>
import Referral from "../home/ReferralInner.vue";
import { mapMutations } from "vuex";
export default {
  data() {
    return { referralCode: "" };
  },
  components: { Referral },
  methods: {
    ...mapMutations(["isReadySetter"]),
  },
  created() {
    this.isReadySetter(3);
    this.referralCode = this.$route.query.id;
    if (this.$route.query.lang) {
      this.$i18n.locale = this.$route.query.lang;
    }
    console.log(this.referralCode);
  },
};
</script>

<style scoped></style>
