<template>
  <v-dialog
    fullscreen
    persistent
    style="background-color: white"
    @input="$emit('input')"
    :value="value"
  >
    <v-card>
      <v-toolbar style="position: sticky; top: 0" dark color="#3d406c">
        <v-btn icon @click="$emit('input')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("fundDetail.title") }}</v-toolbar-title>
      </v-toolbar>

      <div
        v-for="(item, index) in fundDetail"
        :key="index"
        style="padding: 0.7rem 0.7rem 0.7rem 0.7rem; border-bottom: 1px solid #e6e6e6"
      >
        <div class="d-flex">
          <div class="d-flex flex-column">
            <div style="font-size: 0.8rem; color: #999999">
              {{ moment(item.created_at).format("YYYY-MM-DD hh:mm:ss") }}
            </div>
            <div style="font-size: 1.3rem; color: #595959">
              {{ $t(`fundDetail.${item.type}`) }}
            </div>
            <div
              style="font-size: 0.9rem; color: #8c8c8c"
              class="d-flex"
              v-if="item.type === 'sell' || item.type === 'buy'"
            >
              <img
                style="width: 15px; object-fit: contain"
                :src="coinInfo[item.coin_symbol].img"
              />
              <div class="ml-2">{{ item.coin_symbol }}</div>
            </div>
          </div>
          <div class="my-auto ml-auto d-flex flex-column">
            <div
              style="font-size: 1.4rem"
              :class="[
                'ml-auto',
                { fundPositive: item.amount * 1 > 0 },
                { fundNegative: item.amount * 1 < 0 },
              ]"
            >
              {{ item.amount }}
            </div>
            <div style="font-size: 0.8rem; color: #8c8c8c" class="ml-auto">
              {{ $t("fundDetail.balance") + ": " + Math.floor(item.balance * 100) / 100 }}
            </div>
          </div>
        </div>
      </div>
    </v-card>
    <v-overlay :value="isLoading">
      <v-progress-circular
        color="blue darken-4"
        indeterminate
        size="40"
      ></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import coinInfo from "../../utils/coinInfo";
import moment from "moment";
import axios from "axios";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      isLoading: true,
      fundDetail: [],
      type: {
        deposit: "Deposit",
        withdrawal: "Withdrawal",
        buy: "Buy Coin",
        sell: "Sell Coin",
      },
    };
  },
  props: ["value"],
  components: {},
  computed: {
    coinInfo() {
      return coinInfo;
    },
    moment() {
      return moment;
    },
  },
  methods: {
    ...mapMutations(["snackbarSetter"]),
    fetchFundDetail() {
      axios.get("/fund/fund").then(
        (response) => {
          let data = response.data;
          data.sort((a, b) => {
            return new Date(b.date) - new Date(a.date);
          });
          this.fundDetail = data;

          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
    },
  },
  created() {
    this.fetchFundDetail();
  },
  mounted() {},
};
</script>

<style scoped>
.container-deposit {
  touch-action: pan-y !important;
}
.container-deposit div {
  touch-action: pan-y !important;
}
.textfield-input {
  color: #000000;
  border-style: solid;
  border-width: 1px;
  border-color: #8c8c8c;
  border-radius: 0.7rem;

  padding: 0.6rem;
  font-size: 1.2rem;
}
.upload-container {
  color: #000000;
  border-style: solid;
  border-width: 1px;
  border-color: #8c8c8c;
  border-radius: 0.7rem;
  height: 10rem;
  padding: 0.6rem;
  font-size: 1rem;
}
.button-login {
  font-size: 1.2rem;
  background-color: #3d406c;
  color: #ffffff;
  padding: 0.4rem;
  border-radius: 0.75rem;
}
input[type="file"] {
  display: none;
}
.address-left-container {
  padding: 0.2rem 0 0.2rem 0;
  border-width: 1px;
  border-radius: 0.6rem 0 0 0.6rem;
  border-color: #3d406c;
}
.address-right-container {
  padding: 0.2rem 0 0.2rem 0;
  border-width: 1px;
  border-radius: 0 0.6rem 0.6rem 0;
  border-color: #3d406c;
}
.address-selected {
  background-color: #3d406c;
  color: white;
}
.button-login {
  font-size: 1.2rem;
  background-color: #3d406c;
  color: #ffffff;
  padding: 0.4rem;
  border-radius: 0.75rem;
}
textarea:focus,
input:focus {
  outline: none;
}
.text-input-container {
  border: 1px solid #999999;
  border-radius: 0.8rem;
  font-size: 1.2rem;
  padding: 0.6rem 0.7rem 0.6rem 0.7rem;
}
input[type="file"] {
  display: none;
}
.fundPositive {
  color: #30bb87;
}
.fundNegative {
  color: #f7445d;
}
</style>
