<template>
  <div
    class="d-flex flex-column"
    style="background: white; overflow: hidden; height: 100vh"
  >
    <div @click="languageButton" class="ml-auto">
      <div class="d-flex mr-4 mt-4">
        <img class="my-auto w-6 mr-1" :src="languageIcon" />
        <div class="my-auto">{{ getCurrentLang }}</div>
      </div>
    </div>
    <div class="my-auto mx-auto d-flex flex-column" style="width: 85%">
      <img class="mx-auto w-48" :src="logo" />
      <input
        type="text"
        :placeholder="$t('login.username')"
        v-model="username"
        class="textfield-input mt-8"
        autocapitalize="off"
      />
      <input
        type="password"
        :placeholder="$t('login.password')"
        v-model="password"
        class="textfield-input"
        autocapitalize="off"
      />
      <v-alert class="my-2" dense v-if="errorMessage !== ''" elevation="3" type="error">{{
        errorMessage
      }}</v-alert>
      <button @click="loginButton" class="button-login my-2">
        {{ $t("login.login") }}
      </button>
      <div class="d-flex my-1">
        <div class="mr-auto" @click="registerButton">{{ $t("login.createAccount") }}</div>
        <div class="ml-auto" @click="forgetPasswordButton">
          {{ $t("login.forgetPassword") }}
        </div>
      </div>
      <div class="h-20"></div>
    </div>
    <v-overlay :value="isLoading">
      <v-progress-circular color="blue darken-4" indeterminate size="40"></v-progress-circular>
    </v-overlay>
    <v-overlay :dark="false" :value="selectLang">
      <v-card class="px-2 w-48">
        <v-card-title>{{ $t("login.selectLang") }}</v-card-title>
        <v-card-text>
          <div class="d-flex flex-column">
            <div
              @click="changeLanguage(item.key)"
              v-for="(item, index) in language"
              :key="index"
              style="font-size: 1.2rem"
              class="my-1"
            >
              {{ item.name }}
            </div>
          </div></v-card-text
        >
      </v-card>
    </v-overlay>
    <ForgetPassword v-if="dialog.forgetPassword" v-model="dialog.forgetPassword" />
    <Register v-if="dialog.register" v-model="dialog.register" />
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import axios from "axios";

import Register from "./Register.vue";
import ForgetPassword from "./ForgetPassword.vue";
export default {
  data() {
    return {
      dialog: {
        register: false,
        forgetPassword: false,
      },
      language: [
        { name: "English", key: "en" },
        { name: "繁體中文", key: "zh" },
        { name: "简体中文", key: "cn" },
      ],
      username: "",
      password: "",
      errorMessage: "",
      isLoading: false,
      selectLang: false,
      logo: require("../../assets/logo/logo-2.png"),
      languageIcon: require("../../assets/icon/language.png"),
    };
  },
  components: { Register, ForgetPassword },
  computed: {
    getCurrentLang() {
      return this.language.find((element) => element.key === this.$i18n.locale).name;
    },
  },
  methods: {
    ...mapActions(["fetchCoinData", "fetchUserData", "fetchStakingData"]),
    ...mapMutations(["snackbarSetter"]),
    loginButton() {
      if (this.username == "") {
        this.errorMessage = this.$t("login.usernameInvalid");
      } else if (this.password == "") {
        this.errorMessage = this.$t("login.passwordInvalid");
      } else {
        this.isLoading = true;
        axios({
          method: "POST",
          url: process.env.VUE_APP_BACKENDAPI + "auth/userLogin",
          data: {
            Username: this.username,
            Password: this.password,
            appVersion: "1.2",
          },
          withCredentials: true,
        }).then(
          (response) => {
            var result = response.data;
            if (result.status === true) {
              this.password = "";
              this.isLoading = false;
              this.snackbarSetter({ message: this.$t("login.loginSuccess") });
              this.snackbarSetter({ success: true });
              this.snackbarSetter({ visible: true });
              this.$router.push("/");
            } else {
              this.errorMessage = this.$t(`login.${result.message}`);

              this.isLoading = false;
            }
          },
          () => {
            this.Isloading = false;
            alert("Cannot connect to the server");
          }
        );
      }
    },
    languageButton() {
      this.selectLang = true;
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem("language", lang);
      this.selectLang = false;
    },
    registerButton() {
      this.dialog.register = true;
    },
    forgetPasswordButton() {
      this.dialog.forgetPassword = true;
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
.textfield-input {
  color: #000000;
  border-style: solid;
  border-width: 1px;
  border-color: #8c8c8c;
  border-radius: 0.7rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.6rem;
  font-size: 1.2rem;
}
.button-login {
  font-size: 1.2rem;
  background-color: #3d406c;
  color: #ffffff;
  padding: 0.4rem;
  border-radius: 0.75rem;
}
</style>
