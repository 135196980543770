import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from './en.json'
import zh from './zh.json'
import cn from './cn.json'


Vue.use(VueI18n)

const locale = localStorage.getItem('language') || 'en'
const messages = {
    en, zh, cn
}

const i18n = new VueI18n({
    locale,
    messages
})

export default i18n