var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{staticStyle:{"background-color":"white"},attrs:{"fullscreen":"","persistent":"","value":_vm.value},on:{"input":function($event){return _vm.$emit('input')}}},[_c('v-card',[_c('v-toolbar',{staticStyle:{"position":"sticky","top":"0"},attrs:{"dark":"","color":"#3d406c"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('input')}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("order.title")))])],1),_c('div',{staticClass:"d-flex mt-2 mx-1",staticStyle:{"font-size":"0.9rem","color":"#a6a6a6"}},[_c('div',{staticStyle:{"width":"19%"}},[_vm._v(_vm._s(_vm.$t("order.action")))]),_c('div',{staticStyle:{"width":"19%","text-align":"center"}},[_vm._v(_vm._s(_vm.$t("order.symbol")))]),_c('div',{staticStyle:{"width":"24%","text-align":"center"}},[_vm._v(_vm._s(_vm.$t("order.AmtPr")))]),_c('div',{staticStyle:{"width":"24%","text-align":"right"}},[_vm._v(_vm._s(_vm.$t("order.date")))]),_c('div',{staticStyle:{"width":"14%"}})]),_vm._l((_vm.order),function(item,index){return _c('div',{key:index,staticClass:"mx-1 my-2 d-flex"},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"width":"19%"}},[_c('div',{staticStyle:{"font-size":"1.1rem","color":"#999999"}},[_vm._v(" "+_vm._s(_vm.$t(`order.${item.type}`))+" ")]),_c('div',{class:[
            { successContainer: item.status === 'success' },
            { cancelContainer: item.status === 'canceled' },
            { pendingContainer: item.status === 'pending' },
          ],staticStyle:{"width":"4rem","text-align":"center","font-size":"0.8rem"}},[_vm._v(" "+_vm._s(_vm.$t(`order.${item.status}`))+" ")])]),_c('div',{staticClass:"my-auto d-flex",staticStyle:{"width":"19%"}},[_c('div',{staticClass:"mx-auto",staticStyle:{"font-size":"14px","color":"#595959"}},[_vm._v(" "+_vm._s(item.coin_symbol)+" ")])]),_c('div',{staticClass:"my-auto d-flex flex-column",staticStyle:{"width":"24%"}},[_c('div',{staticClass:"mx-auto",staticStyle:{"width":"100%","text-align":"center","font-size":"0.9rem","color":"#595959","text-overflow":"ellipsis","white-space":"nowrap","overflow":"hidden"}},[_vm._v(" "+_vm._s(item.amount)+" ")]),_c('div',{staticClass:"mx-auto",staticStyle:{"font-size":"0.9rem","color":"#999999","text-align":"center","text-overflow":"ellipsis","white-space":"nowrap","overflow":"hidden"}},[_vm._v(" "+_vm._s(item.target_price)+" ")])]),_c('div',{staticClass:"my-auto d-flex flex-column",staticStyle:{"width":"24%","text-align":"right"}},[_c('div',{staticStyle:{"font-size":"0.9rem","color":"#595959"}},[_vm._v(" "+_vm._s(_vm.moment(item.created_at).format("YYYY-MM-DD"))+" ")]),_c('div',{staticStyle:{"font-size":"0.7rem","color":"#999999"}},[_vm._v(" "+_vm._s(_vm.moment(item.created_at).format("hh:mm:ss"))+" ")])]),_c('div',{staticClass:"d-flex",staticStyle:{"width":"14%"}},[_c('img',{staticClass:"mx-auto my-auto",staticStyle:{"width":"1.5rem"},attrs:{"src":item.status === 'success'
              ? _vm.checkCircleIcon
              : item.status === 'canceled'
              ? _vm.minusCircleIcon
              : item.status === 'pending'
              ? _vm.closeOrderIcon
              : ''},on:{"click":function($event){return _vm.cancelOrder(item.order_id, item.status)}}})])])})],2),_c('v-overlay',{attrs:{"value":_vm.isLoading}},[_c('v-progress-circular',{attrs:{"color":"blue darken-4","indeterminate":"","size":"40"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }