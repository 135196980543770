<template>
  <v-app style="overflow: hidden; background-color: #eff7f6">
    <v-main style="overflow: hidden" class="d-flex flex-column">
      <router-view
        class="mx-auto my-auto"
        style="max-width: 600px; max-height: 1000px; overflow: hidden"
      />
    </v-main>
    <v-snackbar
      class="mb-16"
      :color="snackbarGetter.success ? 'green darken-3' : ''"
      v-model="snackbarVisible"
      :timeout="2000"
    >
      <v-icon v-if="snackbarGetter.success" class="mr-2">mdi-check-circle-outline</v-icon>
      {{ snackbarGetter.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbarVisible = false">
          {{ $t("common.close") }}
        </v-btn>
      </template>
    </v-snackbar>

    <v-overlay v-if="$route.name != 'referral'" :dark="false" :value="!pwaMode">
      <v-card class="mx-4">
        <v-card-text>
          <div style="font-size: 1.4rem; color: black">{{ $t("common.reminders") }}</div>
          <div class="mx-auto mt-2">{{ $t("common.addToHomeScreen") }}</div>
          <v-slide-group class="w-full mt-4">
            <v-slide-item>
              <div class="d-flex flex-column">
                <div class="mx-auto">iOS</div>
                <img class="mx-6 mt-1" style="width: 12rem" :src="pwaTutorialIcon" />
              </div>
            </v-slide-item>
            <v-slide-item>
              <div class="d-flex flex-column">
                <div class="mx-auto">Android / Chrome</div>
                <img
                  class="mx-6 mt-1"
                  style="width: 12rem"
                  :src="pwaTutorialAndroidIcon"
                />
              </div>
            </v-slide-item>
          </v-slide-group>
          <v-btn @click="pwaMode = true" dark color="#3d406c" class="mt-4" block>{{
            $t("common.ok")
          }}</v-btn>
        </v-card-text>
      </v-card>
    </v-overlay>
    <v-overlay v-if="isReadyGetter < 3">
      <v-progress-circular
        color="blue darken-4"
        indeterminate
        size="40"
      ></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "App",

  data: () => ({
    pwaMode: true,
    pwaTutorialIcon: require("./assets/pwa-tutorial.png"),
    pwaTutorialAndroidIcon: require("./assets/pwa-tutorial-android.png"),
    //
  }),
  computed: {
    ...mapGetters(["snackbarGetter", "isReadyGetter"]),

    snackbarVisible: {
      get() {
        return this.snackbarGetter.visible;
      },
      set() {
        return this.snackbarSetter({ visible: false });
      },
    },
  },
  methods: {
    ...mapMutations(["snackbarSetter"]),
  },
  created() {
    const isStandalone = window.matchMedia("(display-mode: standalone)").matches;
    if (document.referrer.startsWith("android-app://")) {
      this.pwaMode = true;
    } else if (navigator.standalone || isStandalone) {
      this.pwaMode = true;
    } else {
      this.pwaMode = false;
    }
  },
};
</script>
