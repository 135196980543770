<template>
  <v-dialog
    fullscreen
    persistent
    style="background-color: white"
    @input="$emit('input')"
    :value="value"
  >
    <v-card>
      <v-toolbar style="position: sticky; top: 0" dark color="#3d406c">
        <v-btn icon @click="$emit('input')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("deposit.title") }}</v-toolbar-title>
      </v-toolbar>
      <div
        v-if="tab == 0"
        style="background-color: white; overflow: scroll"
        class="d-flex flex-column px-8 mt-4 container-deposit"
      >
        <div class="text-center mx-auto text-xl">{{ $t("deposit.subtitle") }}</div>
        <div class="text-center mt-2">{{ $t("deposit.address") }}</div>
        <div class="mt-1 text-center d-flex w-40 mx-auto">
          <div
            @click="
              selectedNetwork = 'ERC-20';
              generateQRcode();
            "
            :class="[
              'w-1/2',
              'address-left-container',
              { 'address-selected': selectedNetwork === 'ERC-20' },
            ]"
          >
            ERC-20
          </div>
          <div
            @click="
              selectedNetwork = 'TRC-20';
              generateQRcode();
            "
            :class="[
              'w-1/2',
              'address-right-container',
              { 'address-selected': selectedNetwork === 'TRC-20' },
            ]"
          >
            TRC-20
          </div>
        </div>
        <div
          @click="copyToClipboard"
          style="border-radius: 0.5rem; border: 1px solid #f2f2f2; padding: 0.5rem"
          class="text-center text-sm mx-auto mt-2 w-72"
        >
          {{ getQRCodeString }}
        </div>
        <img class="w-48 mx-auto" :src="getQRCodeImage" />
        <div class="text-sm">
          {{ $t("deposit.description1") }} <strong> USDT/ETH</strong>
          {{ $t("deposit.description2") }}
          <a href="mailto:cs@chainbitvortex.com">cs@chainbitvortex.com</a>
        </div>
        <div class="text-sm mt-2">
          <ol style="list-style-type: decimal">
            <li class="mt-1">
              {{ $t("deposit.point1") }}
            </li>
            <li class="mt-1">
              {{ $t("deposit.point2") }}
            </li>
            <li class="mt-1">{{ $t("deposit.point3") }}</li>
          </ol>
          <div class="mt-2">
            <strong>{{ $t("deposit.pleaseNote") }}</strong>
            {{ $t("deposit.noteContent") }}
          </div>
        </div>
        <button
          @click="
            () => {
              tab = 1;
              assetType = 'USDT';
            }
          "
          class="button-login mt-8 mb-16"
        >
          {{ $t("common.next") }}
        </button>
      </div>
      <div
        v-if="tab === 1"
        style="background-color: white; overflow: scroll"
        class="d-flex flex-column px-8 mt-4 container-deposit"
      >
        <div>{{ $t("deposit.uploadReceipt") }}</div>
        <div class="mt-4 text-input-container">{{ selectedNetwork }}</div>
        <!--     <input
          class="mt-4 text-input-container"
          type="text"
          v-model="txid"
          :placeholder="$t('deposit.transactionID')"
        /> -->
        <select
          v-if="selectedNetwork == 'ERC-20'"
          v-model="assetType"
          class="mt-4 text-input-container"
        >
          <option value="USDT">USDT</option>
          <option value="ETH">ETH</option>
        </select>
        <input
          class="mt-4 text-input-container"
          type="text"
          v-model="amount"
          :placeholder="
            assetType == 'USDT' ? $t('deposit.amountUSDT') : $t('deposit.amountETH')
          "
          inputmode="decimal"
        />
        <div class="mt-8">{{ $t("deposit.uploadReceipts") }}</div>
        <input
          @input="handleFileInput"
          id="depositImage"
          type="file"
          accept="image/png, image/jpg, image/jpeg"
          placeholder="Passport"
        />
        <label for="depositImage" class="mt-1 d-flex text-input-container">
          <img
            v-if="depositImage == ''"
            class="w-16 mx-auto my-auto p-4"
            :src="plusBoxIcon"
          />
          <img
            v-else
            style="object-fit: contain"
            class="max-h-48 mx-auto my-auto"
            :src="depositImage"
          />
        </label>
        <button @click="uploadButton" class="button-login mt-8 mb-16">
          {{ $t("deposit.upload") }}
        </button>
      </div>
    </v-card>
    <v-overlay :value="isLoading">
      <v-progress-circular
        color="blue darken-4"
        indeterminate
        size="40"
      ></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import axios from "../../axios";
import { mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      qrcodeImg: "",
      isLoading: false,
      tab: 0,
      depositImage: "",
      selectedNetwork: "ERC-20",
      assetType: "USDT",
      txid: "",
      amount: "",
      address: {
        erc: "",
        trc: "",
      },
      plusBoxIcon: require("../../assets/icon/plus-box-outline.png"),
    };
  },
  props: ["value"],
  components: {},
  computed: {
    ...mapGetters(["walletAddressGetter"]),
    getQRCodeString() {
      if (this.selectedNetwork == "ERC-20") {
        return this.address.erc;
      } else if (this.selectedNetwork == "TRC-20") {
        return this.address.trc;
      } else {
        return 0;
      }
    },
    getQRCodeImage() {
      return this.qrcodeImg;
    },
  },
  methods: {
    ...mapMutations(["snackbarSetter"]),
    async getAddress() {
      let res = await axios.get("/common/depositAddress");
      this.address.erc = res.data.eth.data;
      this.address.trc = res.data.trc.data;
      this.generateQRcode();
    },
    uploadButton() {
      if (this.amount === "") {
        this.snackbarSetter({
          success: false,
          message: this.$t("deposit.emptyAmount"),
          visible: true,
        });
      } else if (isNaN(this.amount)) {
        this.snackbarSetter({
          success: false,
          message: this.$t("deposit.isNotANumber"),
          visible: true,
        });
      } else if (this.amount < 100 && this.assetType == "USDT") {
        this.snackbarSetter({
          success: false,
          message: this.$t("deposit.tooLowAmount"),
          visible: true,
        });
      } else if (this.depositImage === "") {
        this.snackbarSetter({
          success: false,
          message: this.$t("deposit.emptyImage"),
          visible: true,
        });
      } else {
        this.isLoading = true;
        axios
          .post("/user/deposit", {
            image: this.depositImage,
            amount: this.amount,
          })
          .then(
            (response) => {
              this.isLoading = false;
              if (response.data.status) {
                this.snackbarSetter({
                  success: true,
                  message: this.$t("deposit.successContent"),
                  visible: true,
                });
                this.$emit("input");
              } else {
                this.snackbarSetter({
                  success: false,
                  message: this.$t("deposit.failedContent"),
                  visible: true,
                });
              }
            },
            () => {
              this.isLoading = false;
            }
          );
      }
    },
    handleFileInput(e) {
      console.log(e);
      if (e.target.files && e.target.files[0]) {
        let img = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          const dataURI = e.target.result;
          this.depositImage = dataURI;
        };
        reader.readAsDataURL(img);
      }
    },
    generateQRcode() {
      var QRCode = require("qrcode");
      QRCode.toDataURL(this.getQRCodeString, (err, url) => {
        this.qrcodeImg = url;
      });
    },
    copyToClipboard() {
      this.$copyText(this.getQRCodeString).then(
        (e) => {
          this.snackbarSetter({
            success: true,
            visible: true,
            message: this.$t("deposit.copyToClip"),
          });
          console.log(e);
        },
        (e) => {
          this.snackbarSetter({
            success: false,
            visible: true,
            message: this.$t("deposit.failToCopy"),
          });
          console.log(e);
        }
      );
    },
  },
  created() {
    this.getAddress();
  },
  mounted() {},
};
</script>

<style scoped>
.container-deposit {
  touch-action: pan-y !important;
}
.container-deposit div {
  touch-action: pan-y !important;
}
.textfield-input {
  color: #000000;
  border-style: solid;
  border-width: 1px;
  border-color: #8c8c8c;
  border-radius: 0.7rem;

  padding: 0.6rem;
  font-size: 1.2rem;
}
.upload-container {
  color: #000000;
  border-style: solid;
  border-width: 1px;
  border-color: #8c8c8c;
  border-radius: 0.7rem;
  height: 10rem;
  padding: 0.6rem;
  font-size: 1rem;
}
.button-login {
  font-size: 1.2rem;
  background-color: #3d406c;
  color: #ffffff;
  padding: 0.4rem;
  border-radius: 0.75rem;
}
input[type="file"] {
  display: none;
}
.address-left-container {
  padding: 0.2rem 0 0.2rem 0;
  border-width: 1px;
  border-radius: 0.6rem 0 0 0.6rem;
  border-color: #3d406c;
}
.address-right-container {
  padding: 0.2rem 0 0.2rem 0;
  border-width: 1px;
  border-radius: 0 0.6rem 0.6rem 0;
  border-color: #3d406c;
}
.address-selected {
  background-color: #3d406c;
  color: white;
}
.button-login {
  font-size: 1.2rem;
  background-color: #3d406c;
  color: #ffffff;
  padding: 0.4rem;
  border-radius: 0.75rem;
}
textarea:focus,
input:focus {
  outline: none;
}
.text-input-container {
  border: 1px solid #999999;
  border-radius: 0.8rem;
  font-size: 1.2rem;
  padding: 0.6rem 0.7rem 0.6rem 0.7rem;
}
input[type="file"] {
  display: none;
}
</style>
