import Vue from 'vue'
import Vuex from 'vuex'
import axios from '../axios'
import createPresistedState from "vuex-persistedstate"
//import router from '../router/index'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    cryptoIcon: {
      USDT: require("../assets/crypto-icon/825.png"),
      ADA: require("../assets/crypto-icon/ada.png"),
      BNB: require("../assets/crypto-icon/bnb.png"),
      BTC: require("../assets/crypto-icon/btc.png"),
      ETH: require("../assets/crypto-icon/eth.png"),
      DOGE: require("../assets/crypto-icon/Dogecoin-DOGE-icon.png"),
      AITS: require("../assets/crypto-icon/m-icon-7.png")
    },
    loginDialogShow: false,
    coinData: [],
    stakingData: [],
    userData: {},
    walletAddress: {},
    homeIndex: 0,
    tradeSymbol: "BTCUSDT",
    userInfoFirstLoad: true,
    snackbar: {
      visible: false,
      message: "",
      success: false,
    },
    isReady: 0,
    // auth
    auth: {
      isLoggedIn: false,
      token: ""
    },
  
  },
  getters: {
    
    authGetter: (state) => state.auth,
    loginDialogShowGetter: (state) => state.loginDialogShow,
    cryptoIconGetter: (state) => state.cryptoIcon,
    walletAddressGetter: (state) => state.walletAddress,
    snackbarGetter: (state) => state.snackbar,
    tradeSymbolGetter: (state) => state.tradeSymbol,
    userDataGetter: (state) => state.userData,
    coinDataGetter: (state) => state.coinData,
    stakingDataGetter: (state) => state.stakingData,
    coinDataGainerGetter: (state) =>
      state.coinData.sort((a, b) => b.change_pct_24h - a.change_pct_24h).slice(0, 8),
    coinDataLoserGetter: (state) =>
      state.coinData.sort((a, b) => a.change_pct_24h - b.change_pct_24h).slice(0, 8),
    coinBTCGetter: (state) => {
      let found = state.coinData.find(element => element.coin_symbol === "BTCUSDT")
      if (typeof found === "undefined") {
        return { P: 0, c: 0 }
      } else {
        return found
      }
    },
    coinETHGetter: (state) => {
      let found = state.coinData.find(element => element.coin_symbol === "ETHUSDT")
      if (typeof found === "undefined") {
        return { P: 0, c: 0 }
      } else {
        return found
      }
    },
    coinBNBGetter: (state) => {
      let found = state.coinData.find(element => element.coin_symbol === "BNBUSDT")
      if (typeof found === "undefined") {
        return { P: 0, c: 0 }
      } else {
        return found
      }
    },
    homeIndexGetter: (state) => state.homeIndex,
    isReadyGetter: (state) => state.isReady

  },
  mutations: {
    tokenSetter(state, data) {
      state.auth.token = data;
    },
    authSetter(state, data) {
      state.auth = data
    },
    loginDialogShowSetter(state, status) {
      state.loginDialogShow = status
    },
    snackbarSetter(state, data) {
      Object.assign(state.snackbar, data)
    },
    tradeSymbolSetter(state, symbol) {
      state.tradeSymbol = symbol
    },
    userDataSetter(state, data) {
      state.userData = data;
    },
    coinDataSetter(state, data) {
      state.coinData = data;
    },
    stakingDataSetter(state, data) {
      state.stakingData = data;
    },
    walletAddressSetter(state, data) {
      state.walletAddress = data;
    },
    homeIndexSetter(state, index) {
      state.homeIndex = index;
    },
    isReadySetter(state, bool) {
      state.isReady = bool;
    },

  },
  actions: {
    fetchCoinData({ commit, state }) {
      const symbol_head = (symbol) => {
        return symbol.substring(0, symbol.search("USDT"));
      }
      const symbol_end = (symbol) => {
        return "/" + symbol.substring(symbol.search("USDT"));
      }
      axios.create({
        baseURL: process.env.VUE_APP_BACKENDAPI,
      }).get(`/coin/allCoins`)
        .then(
          (response) => {
            commit("isReadySetter", state.isReady + 1);
            let arrayData = response.data.coins;
            for (let i = 0; i < arrayData.length; i++) {
              arrayData[i].symbol_head = symbol_head(arrayData[i].coin_symbol);
              arrayData[i].symbol_end = symbol_end(arrayData[i].coin_symbol);
            }
            commit("coinDataSetter", arrayData)
          },
          (error) => {
            console.error(error);
          }
        );
    },
    fetchUserData({ commit, state }) {
      return new Promise((resolve) => {
        if (!state.auth.isLoggedIn) {
          return
        }
        axios.get(
          "/user/userData"
        ).then((res) => {
  
          commit('userDataSetter', res.data)
          resolve()
        })

      })


    },
    fetchStakingData({ commit, state }) {
      axios({
        method: "POST",
        url: process.env.VUE_APP_BACKENDAPI + "staking/getAllActiveOrder",
      }).then(
        (response) => {
          commit("isReadySetter", state.isReady + 1)
          if (response.data.status) {
            var arrayData = response.data.result;

            arrayData.forEach((element, index) => {
              var dateParse1 = new Date(Date.parse(element.valueDate));
              var hours = dateParse1.getHours();
              var minutes = dateParse1.getMinutes();
              var seconds = dateParse1.getSeconds();
              if (hours < 10) {
                hours = "0" + hours;
              }
              if (minutes < 10) {
                minutes = "0" + minutes;
              }
              minutes;
              if (seconds < 10) {
                seconds = "0" + seconds;
              }
              arrayData[index].formattedValueDateTime =
                dateParse1.getFullYear() +
                "-" +
                (dateParse1.getMonth() + 1) +
                "-" +
                dateParse1.getDate() +
                " " +
                hours +
                ":" +
                minutes +
                ":" +
                seconds;
              arrayData[index].formattedValueDate =
                dateParse1.getFullYear() +
                "-" +
                (dateParse1.getMonth() + 1) +
                "-" +
                dateParse1.getDate();

              var dateParse2 = new Date(Date.parse(element.redemptionDate));
              arrayData[index].formattedRedemptionDate =
                dateParse2.getFullYear() +
                "-" +
                (dateParse2.getMonth() + 1) +
                "-" +
                dateParse2.getDate();
              arrayData[index].period =
                (dateParse2.getTime() - dateParse1.getTime()) / (1000 * 3600 * 24);
              /*     var cryptoIconList = this.$store.state.cryptoIconList;
                  var src = cryptoIconList.find((ele) => element.name == ele.name);
                  arrayData[index].src = src; */
            });
            arrayData = arrayData.reverse();
            commit("stakingDataSetter", arrayData);
          }
        },
        (error) => {

          console.error(error);
        }
      );

    }
  },
  plugins: [
    createPresistedState({
      storage: window.localStorage
    })
  ]
})
