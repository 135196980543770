<template>
  <div class="header-container">
    <div
      :class="[
        'd-flex',
        { 'header-container': homeIndexGetter === 0 },
        { 'header-container-white': homeIndexGetter === 1 || homeIndexGetter === 2 },
        { 'header-container-light': homeIndexGetter === 3 },
      ]"
    >
      <div v-if="homeIndexGetter == 3" class="w-16 mr-auto"></div>
      <img
        style="width: 200px"
        class="pt-3 pb-3   mx-auto"
        :src="homeIndexGetter == 0 || homeIndexGetter == 3 ? logoWhite : logoFilled"
      />
      <v-icon
        color="#F2EFFB"
        @click="dialog.settings = true"
        v-if="homeIndexGetter == 3"
        class="w-16 my-auto ml-auto"
        >mdi-account-cog</v-icon
      >
    </div>
    <SettingsPage v-if="dialog.settings" v-model="dialog.settings" />
  </div>
</template>
<script>
import SettingsPage from "../account/Settings.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      dialog: { settings: false },
      logoWhite: require("../../assets/logo/logo-5.png"),
      logoFilled: require("../../assets/logo/logo-1.png"),
      settingIcon: require("../../assets/icon/cog-outline.png"),
    };
  },
  components: {
    SettingsPage,
  },
  computed: { ...mapGetters(["homeIndexGetter"]) },
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style scoped>
.header-container {
  background-color: #242640 !important;
}
.header-container-white {
  background-color: white !important;
}

.header-container-light {
  background-color: #636689 !important;
}
</style>
