<template>
  <v-dialog
    fullscreen
    persistent
    style="background-color: white"
    @input="$emit('input')"
    :value="value"
  >
    <v-card>
      <v-toolbar style="position: sticky; top: 0" dark color="#3d406c">
        <v-btn icon @click="$emit('input')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("withdrawal.title") }}</v-toolbar-title>
      </v-toolbar>
      <div
        style="background-color: white; overflow: scroll"
        class="d-flex flex-column px-4 mt-4 container-deposit"
      >
        <input
          class="mt-4 text-input-container"
          type="text"
          v-model="address"
          :placeholder="$t('withdrawal.address')"
        />
        <input
          class="mt-4 text-input-container"
          type="text"
          v-model="amount"
          :placeholder="$t('withdrawal.amount')"
          inputmode="decimal"
        />

        <div class="mt-4">
          {{ $t("withdrawal.avblUSDT") + avblUSDT }}
        </div>
        <div class="mt-4" style="font-size: 0.9rem">
          <strong>{{ $t("withdrawal.pleaseNote") }} </strong
          >{{ $t("withdrawal.noteContent") }}
        </div>
        <button @click="submitButton" class="button-login mt-8 mb-16">
          {{ $t("withdrawal.submit") }}
        </button>
      </div>
    </v-card>
    <v-overlay :value="isLoading">
      <v-progress-circular
        color="blue darken-4"
        indeterminate
        size="40"
      ></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      isLoading: false,
      address: "",
      amount: "",
    };
  },
  props: ["value"],
  components: {},
  computed: {
    ...mapGetters(["walletAddressGetter", "userDataGetter"]),
    avblUSDT() {
      let usdt = this.userDataGetter.user_coin.find(
        (element) => element.coin_symbol == "USDT"
      );
      return Math.floor((usdt.amount - usdt.reserved) * 100) / 100;
    },
  },
  methods: {
    ...mapMutations(["snackbarSetter"]),
    submitButton() {
      if (this.address == "") {
        this.snackbarSetter({
          success: false,
          message: this.$t("withdrawal.emptyAddress"),
          visible: true,
        });
      } else if (this.amount == "") {
        this.snackbarSetter({
          success: false,
          message: this.$t("withdrawal.emptyAmount"),
          visible: true,
        });
      } else if (this.amount < 50) {
        this.snackbarSetter({
          success: false,
          message: this.$t("withdrawal.amountTooLow"),
          visible: true,
        });
      } else if (this.amount > this.avblUSDT) {
        this.snackbarSetter({
          success: false,
          message: this.$t("withdrawal.amountTooLarge"),
          visible: true,
        });
      } else if (
        confirm(
          `${this.$t("withdrawal.yourAddress")}: ${this.address}\n${this.$t(
            "withdrawal.amount"
          )}: ${this.amount}`
        )
      ) {
        this.isLoading = true;
        axios.post("/user/withdrawal",{
          amount:this.amount,
          address:this.address
        }).then(
          (response) => {
            this.isLoading = false;
            if (response.data.status) {
              this.snackbarSetter({
                success: true,
                message: this.$t("common.succese"),
                visible: true,
              });
              this.$emit("input");
            } else {
              this.snackbarSetter({
                success: false,
                message: this.$t("common.fail"),
                visible: true,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    },
  },
  created() {},
  mounted() {},
};
</script>

<style scoped>
.container-deposit {
  touch-action: pan-y !important;
}
.container-deposit div {
  touch-action: pan-y !important;
}
.textfield-input {
  color: #000000;
  border-style: solid;
  border-width: 1px;
  border-color: #8c8c8c;
  border-radius: 0.7rem;

  padding: 0.6rem;
  font-size: 1.2rem;
}
.upload-container {
  color: #000000;
  border-style: solid;
  border-width: 1px;
  border-color: #8c8c8c;
  border-radius: 0.7rem;
  height: 10rem;
  padding: 0.6rem;
  font-size: 1rem;
}
.button-login {
  font-size: 1.2rem;
  background-color: #3d406c;
  color: #ffffff;
  padding: 0.4rem;
  border-radius: 0.75rem;
}
input[type="file"] {
  display: none;
}
.address-left-container {
  padding: 0.2rem 0 0.2rem 0;
  border-width: 1px;
  border-radius: 0.6rem 0 0 0.6rem;
  border-color: #3d406c;
}
.address-right-container {
  padding: 0.2rem 0 0.2rem 0;
  border-width: 1px;
  border-radius: 0 0.6rem 0.6rem 0;
  border-color: #3d406c;
}
.address-selected {
  background-color: #3d406c;
  color: white;
}
.button-login {
  font-size: 1.2rem;
  background-color: #3d406c;
  color: #ffffff;
  padding: 0.4rem;
  border-radius: 0.75rem;
}
textarea:focus,
input:focus {
  outline: none;
}
.text-input-container {
  border: 1px solid #999999;
  border-radius: 0.8rem;
  font-size: 1.2rem;
  padding: 0.6rem 0.7rem 0.6rem 0.7rem;
}
input[type="file"] {
  display: none;
}
</style>
