const coinInfo =
{
    "APTUSDT": { name: "Aptos", symbol: "APT", img: require('../assets/c-icon/APT.png') },
    "ATOMUSDT": { name: "Atomic Coin", symbol: "ATOM", img: require('../assets/c-icon/ATOM.png') },
    "AVAXUSDT": { name: "Avalanche", symbol: "AVAX", img: require('../assets/c-icon/AVAX.png') },
    "BTCUSDT": { name: "BITCOIN", symbol: "BTC", img: require('../assets/c-icon/BTC.png') },
    "SOLUSDT": { name: "Solana", symbol: "SOL", img: require('../assets/c-icon/SOL.png') },
    "OPUSDT": { name: "OPU Coin", symbol: "OPU", img: require('../assets/c-icon/OPU.png') },
    "TRXUSDT": { name: "TRON", symbol: "TRX", img: require('../assets/c-icon/TRX.png') },
    "SANDUSDT": { name: "The Sandbox", symbol: "SAND", img: require('../assets/c-icon/SAND.png') },
    "NEARUSDT": { name: "NEAR Protocol", symbol: "NEAR", img: require('../assets/c-icon/NEAR.png') },
    "FLOWUSDT": { name: "Flow", symbol: "FLOW", img: require('../assets/c-icon/FLOW.png') },
    "ICPUSDT": { name: "Internet Computer", symbol: "ICP", img: require('../assets/c-icon/ICP.png') },
    "IMXUSDT": { name: "Immutable", symbol: "IMX", img: require('../assets/c-icon/IMX.png') },
    "INJUSDT": { name: "Injective", symbol: "INJ", img: require('../assets/c-icon/INJ.png') },
    "ETHUSDT": { name: "ETHEREUM", symbol: "ETH", img: require('../assets/c-icon/ETH.png') },
    "BNBUSDT": { name: "BINANCE COIN", symbol: "BNB", img: require('../assets/c-icon/BNB.png') },
    "XRPUSDT": { name: "XRPUSDT", symbol: "XRP", img: require('../assets/c-icon/XRP.png') },
    "BCHUSDT": { name: "BITCOIN CASH", symbol: "BCH", img: require('../assets/c-icon/BCH.png') },
    "LTCUSDT": { name: "LITECOIN", symbol: "LTC", img: require('../assets/c-icon/LTC.png') },
    "NEOUSDT": { name: "NEO", symbol: "NEO", img: require('../assets/c-icon/NEO.png') },
    "THETAUSDT": { name: "THETA COIN", symbol: "THETA", img: require('../assets/c-icon/THETA.png') },
    "SXPUSDT": { name: "SXP", symbol: "SXP", img: require('../assets/c-icon/SXP.png') },
    "OMGUSDT": { name: "OMISEGO", symbol: "OMG", img: require('../assets/c-icon/OMG.png') },
    "LINKUSDT": { name: "CHAINLINK", symbol: "LINK", img: require('../assets/c-icon/LINK.png') },
    "KAVAUSDT": { name: "KAVA", symbol: "KAVA", img: require('../assets/c-icon/KAVA.png') },
    "REEFUSDT": { name: "REEF", symbol: "REEF", img: require('../assets/c-icon/REEF.png') },
    "DOTUSDT": { name: "DOT", symbol: "DOT", img: require('../assets/c-icon/DOT.png') },
    "ADAUSDT": { name: "CARDANO", symbol: "ADA", img: require('../assets/c-icon/ADA.png') },
    "DOGEUSDT": { name: "DOGECOIN", symbol: "DOGE", img: require('../assets/c-icon/DOGE.png') },
    "ETCUSDT": { name: "ETHEREUM CLASSIC", symbol: "ETC", img: require('../assets/c-icon/ETC.png') },
    "XLMUSDT": { name: "STELLAR", symbol: "XLM", img: require('../assets/c-icon/XLM.png') },
    "OGNUSDT": { name: "ORIGIN PROTOCOL", symbol: "OGN", img: require('../assets/c-icon/OGN.png') },
    "MATICUSDT": { name: "MATIC NETWORK", symbol: "MATIC", img: require('../assets/c-icon/MATIC.png') },
    "FTMUSDT": { name: "FANTOM", symbol: "FTM", img: require('../assets/c-icon/FTM.png') },
    "UNIUSDT": { name: "UNI", symbol: "UNI", img: require('../assets/c-icon/UNI.png') },
    "XEMUSDT": { name: "NEM", symbol: "XEM", img: require('../assets/c-icon/XEM.png') },
    "EOSUSDT": { name: "EOS", symbol: "EOS", img: require('../assets/c-icon/EOS.png') },
    "SHIBUSDT": { name: "SHIBA INU", symbol: "SHIB", img: require('../assets/c-icon/SHIB.png') },
    "SUSHIUSDT": { name: "Sushi", symbol: "SUSHI", img: require('../assets/c-icon/SUSHI.png') },
    "YFIUSDT": { name: "yearn.finance", symbol: "YFI", img: require('../assets/c-icon/YFI.png') },
    "AXSUSDT": { name: "Axie Infinity", symbol: "AXS", img: require('../assets/c-icon/AXS.png') },
    "TLMUSDT": { name: "Alien Worlds", symbol: "TLM", img: require('../assets/c-icon/TLM.png') },
    "ALICEUSDT": { name: "My Neighbor Alice", symbol: "ALICE", img: require('../assets/c-icon/ALICE.png') },
    "BHVTUSDT": { name: "Bitcoin Halving Token", symbol: "BHVTUSDT", img: require('../assets/c-icon/BHVT.png') }  ,
    "USDT": { name: "USDT", symbol: "USDT", img: require('../assets/c-icon/USDT.png') }
}

export default coinInfo