<template>
  <div>
    <v-overlay v-if="isLoading">
      <v-progress-circular :size="50" color="primary" indeterminate>
      </v-progress-circular>
    </v-overlay>
    <div v-if="tab == 0">
      <div class="px-4 d-flex flex-column">
        <img
          class="mt-8 mx-auto"
          style="width: 7rem"
          :src="require('../../assets/giftbox.png')"
        />
        <div
          class="text-center mx-auto mt-6"
          style="line-height: 1.2; width: 80%; font-size: 1.6rem; color: #66bb6a"
        >
          <strong> {{ $t("referral.heading-1") }}</strong>
        </div>
        <div style="color: #8c8c8c; line-height: 1.3" class="text-center mx-auto mt-2">
          {{ $t("referral.heading-2") }}
        </div>
        <div class="mt-6" style="font-size: 1.2rem; color: #26004d">
          {{ $t("referral.yourReferralCode") }}
        </div>
        <div
          class="text-center mt-1"
          style="
            font-size: 1.2rem;
            background-color: #f2f2f2;
            border-radius: 5px;
            padding: 10px;
            color: #595959;
          "
        >
          {{ "COMING SOON" }}
        </div>
      </div>
      <div
        class="my-4"
        style="height: 10px; width: 100%; background-color: #eeeeee"
      ></div>
      <div style="" class="px-4 pt-4">
        <v-btn  color="#00897B" @click="tab = 1" dark block large>
          <v-icon size="23" left>mdi-account-details</v-icon>
          {{ $t("referral.yourReferrals") }}
        </v-btn>
        <v-btn  color="#3949AB" @click="tab = 2" dark block large class="mt-4">
          <v-icon size="23" left>mdi-bitcoin</v-icon>
          {{ $t("referral.yourRewards") }}
        </v-btn>
        <v-btn
          color="#0277BD"
          @click="onEnterReferralCodeClick"
          dark
          block
          large
          class="mt-4"
        >
          <v-icon size="23" left>mdi-account-plus</v-icon>
          {{ $t("referral.enterReferralCode") }}
        </v-btn>
        <v-btn color="#424242" @click="tab = 3" dark block large class="mt-4">{{
          $t("referral.details")
        }}</v-btn>
        <div style="height: 200px"></div>
      </div>
    </div>
    <div v-else-if="tab == 1" class="text-center px-2">
      <div style="z-index: 1" class="my-2 d-flex">
        <v-icon style="z-index: 1" @click="tab = 0" size="40">mdi-chevron-left</v-icon>
      </div>

      <div v-if="referralsList.length > 0">
        <div
          class="mt-2 d-flex pb-2"
          style="color: #999999; border-bottom: 1px solid #e6e6e6"
        >
          <div style="width: 10%"></div>
          <div style="width: 30%" class="mx-auto">{{ $t("referral.id") }}</div>
          <div style="width: 30%" class="mx-auto">{{ $t("referral.name") }}</div>
          <div style="width: 30%" class="mx-auto">{{ $t("referral.date") }}</div>
        </div>
        <div
          style="border-bottom: 1px solid #e6e6e6"
          class="py-2 d-flex"
          v-for="(item, index) in referralsList"
          :key="index"
        >
          <div style="width: 10%">
            <v-avatar size="25" color="#1E88E5">
              <v-icon dark> mdi-account </v-icon>
            </v-avatar>
          </div>
          <div style="width: 30%" class="my-auto mx-auto">{{ `****${item.uid}` }}</div>
          <div style="width: 30%" class="my-auto mx-auto">{{ `${item.name}` }}</div>
          <div style="width: 30%" class="my-auto mx-auto">
            {{ moment(`${item.date}`).format("YYYY-MM-DD") }}
          </div>
        </div>
      </div>
      <div v-else>
        <v-icon class="mt-16" color="#757575" size="100">
          mdi-clipboard-text-outline
        </v-icon>
        <div style="color: #757575; font-size: 20px">{{ $t("referral.noRecord") }}</div>
      </div>
      <div style="height: 500px"></div>
    </div>
    <div v-else-if="tab == 2">
      <div
        style="background-color: #636689; color: white"
        class="pa-3 d-flex flex-column"
      >
        <div class="d-flex">
          <v-icon dark @click="tab = 0" size="40">mdi-chevron-left</v-icon>
        </div>
        <div
          style="border: 1px solid #a3a3c2; border-radius: 10px; font-size: 25px"
          class="pa-3 mt-2"
        >
          <div style="font-size: 15px; color: #d9d9d9">
            {{ $t("referral.totalReferralBonus") }}
          </div>
          <div>{{ totalRewardUSDT.toFixed(2) }}</div>
        </div>
        <div style="height: 40px"></div>
      </div>
      <div
        class="pt-2 px-3"
        style="background-color: white; border-radius: 20px; margin-top: -20px"
      >
        <div style="color: #808080; font-size: 1.2rem" class="text-center">
          {{ $t("referral.rewards") }}
        </div>
        <div v-if="rewardsList.length > 0">
          <div
            class="py-2 d-flex"
            style="font-size: 1rem; color: #737373; border-bottom: 1px solid #e6e6e6"
            v-for="(item, index) in rewardsList"
            :key="index"
          >
            <div class="mr-auto d-flex flex-column">
              <div class="d-flex">
                <img
                  style="height: 1.4rem"
                  class="my-auto"
                  :src="cryptoIconGetter[item.asset]"
                />
                <div class="ml-2 my-auto" style="font-size: 1.2rem">{{ item.asset }}</div>
              </div>
              <div class="mt-2 mr-auto">
                {{ `${$t("referral.rewardLevel")}: ${item.rewardLevel * 100}%` }}
              </div>
              <div class="mr-auto">
                {{ `${$t("referral.amount")}: ${item.amount}` }}
              </div>
              <div class="mr-auto">
                {{ `${$t("referral.from")}: ****${item.from}` }}
              </div>
            </div>
            <div style="font-size: 0.9rem" class="ml-auto mt-1 d-flex flex-column">
              <div>{{ moment(item.date).format("YYYY-MM-DD HH:mm:ss") }}</div>
            </div>
          </div>
        </div>
        <div v-else>
          <v-icon class="mt-16" color="#757575" size="100">
            mdi-clipboard-text-outline
          </v-icon>
          <div style="color: #757575; font-size: 20px">{{ $t("referral.noRecord") }}</div>
        </div>
      </div>

      <div style="height: 500px"></div>
    </div>
    <div v-else-if="tab == 3">
      <div class="px-2">
        <div class="d-flex pt-1">
          <v-icon @click="tab = 0" size="40">mdi-chevron-left</v-icon>
        </div>

        <div style="font-size: 23px" class="mt-2 text-center">
          {{ $t("referral.commissionTitle") }}
        </div>
        <table style="width: 100%; border: 1px solid" class="mt-2 text-center">
          <tr>
            <td style="width: 50%">{{ $t("referral.tableCol1") }}</td>
            <td style="width: 50%">{{ $t("referral.tableCol2") }}</td>
          </tr>
          <tr>
            <td>1-2</td>
            <td>3%</td>
          </tr>
          <tr>
            <td>3-5</td>
            <td>5%</td>
          </tr>
          <tr>
            <td>6 {{ $t("referral.orAbove") }}</td>
            <td>10%</td>
          </tr>
        </table>

        <div class="mt-4">
          <ul>
            <li>
              <div style="font-weight: bold; color: #595959">
                {{ $t("referral.equation") }}
              </div>
              <div style="color: #737373">
                {{ $t("referral.equationExample") }}
              </div>
            </li>
            <li>
              <div style="font-weight: bold; color: #595959">
                {{ $t("referral.note") }}
              </div>
              <div style="color: #737373">
                {{ $t("referral.noteContent") }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <v-dialog v-model="showEnterReferral">
      <v-card>
        <v-card-title>Enter referral code</v-card-title>
        <v-card-text>
          <v-text-field v-model="referralCodeField" label="Referral code"></v-text-field>
          <v-btn @click="submitReferralCode" dark color="#1E88E5">Submit</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import moment from "moment";
import axios from "axios";
export default {
  data() {
    return {
      tab: 0,
      referralsList: [],
      rewardsList: [],
      referer: null,
      isLoading: false,
      showEnterReferral: false,
      referralCodeField: "COMING SOON",
    };
  },
  props: ["referralCode"],
  methods: {
    ...mapMutations(["snackbarSetter"]),
    ...mapActions(["fetchCoinData"]),
    onEnterReferralCodeClick() {
      if (this.referer) {
        this.snackbarSetter({ message: this.$t("referral.alertMessage") });
        this.snackbarSetter({ success: false });
        this.snackbarSetter({ visible: true });
        return;
      }
      this.referralCodeField = "";
      this.showEnterReferral = true;
    },
    submitReferralCode() {
      axios({
        method: "POST",
        url: process.env.VUE_APP_BACKENDAPI + "user/setReferer",
        data: { referralCode: this.referralCodeField, referer: this.referralCode },
      }).then((response) => {
        if (response.data.status) {
          this.snackbarSetter({ message: "Success" });
          this.snackbarSetter({ success: true });
        } else {
          this.snackbarSetter({ message: "Invalid referral code" });
          this.snackbarSetter({ success: false });
        }
        this.snackbarSetter({ visible: true });
        this.showEnterReferral = false;
        this.getReferrals();
      });
    },
    getReferrals() {
      axios({
        method: "GET",
        url:
          process.env.VUE_APP_BACKENDAPI +
          "user/getReferralDetail?id=" +
          this.referralCode,
      }).then((response) => {
        this.referralsList = response.data.referralsList;
        this.rewardsList = response.data.referralRewardList.sort((a, b) =>
          new Date(a.date).getTime() < new Date(b.date).getTime() ? 1 : -1
        );

        this.referer = response.data.referer;
        this.isLoading = false;
      });
    },
  },
  computed: {
    ...mapGetters(["cryptoIconGetter", "coinDataGetter"]),
    moment() {
      return moment;
    },
    totalRewardUSDT() {
      if (this.rewardsList.length == 0) {
        return 0;
      } else {
        let amount = this.rewardsList.reduce((acc, reward) => {
          if (reward.asset == "USDT") {
            acc += reward.amount * 1;
          } else {
            let coin = this.coinDataGetter.find(
              (element) => element.symbol_head == reward.asset
            );
            if (coin) {
              acc += coin.c * reward.amount * 1;
            }
          }
          return acc;
        }, 0);
        return amount;
      }
    },
  },
  created() {
    
/*     this.getReferrals(); */
  },
};
</script>

<style scoped>
.tab-underline {
  border-bottom: 1px solid #19194d;
  color: #000000 !important;
}
table,
tr,
td {
  border: 1px solid #595959;
  padding: 5px;
}
ul {
  list-style-type: disc;
}
.column-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
