<template>
  <v-dialog fullscreen persistent @input="$emit('input')" :value="value">
    <v-card>
      <v-toolbar style="position: sticky; top: 0" dark color="#3d406c">
        <v-btn icon @click="$emit('input')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("staking.title") }}</v-toolbar-title>
      </v-toolbar>

      <div class="container-staking d-flex flex-column overflow-scroll">
        <div class="h-36 d-flex flex-column" style="color: white">
          <div class="mx-auto mt-auto" style="font-size: 1.7rem">
            <strong>{{ $t("staking.heading") }}</strong>
          </div>
          <div class="mx-auto mb-auto" style="font-size: 1.1rem">
            {{ $t("staking.subheading") }}
          </div>
        </div>
        <div style="border-radius: 1rem 1rem 0 0; background-color: #f1f0f0" class="p-2">
          <div
            class="d-flex flex-column p-3 my-3 elevation-1"
            style="background-color: white; border-radius: 0.7rem"
            v-for="(item, index) in stakingList"
            :key="index"
          >
            <div class="d-flex">
              <img
                style="width: 1.5rem"
                :src="
                  item.coin_symbol == 'USDT'
                    ? coinInfo['USDT'].img
                    : coinInfo[item.coin_symbol + 'USDT'].img
                "
              />
              <div class="ml-2 my-auto">{{ item.coin_symbol }}</div>
            </div>
            <div class="d-flex">
              <div class="d-flex flex-column w-1/2">
                <div style="color: #999999; font-size: 0.9rem">
                  {{ $t("staking.AIR") }}
                </div>
                <div style="color: #30bb87; font-size: 1.2rem">
                  {{ item.rate[item.checked] + "%" }}
                </div>
              </div>
              <div class="d-flex flex-column w-1/2">
                <div style="color: #999999; font-size: 0.9rem">
                  {{ $t("staking.min") }}
                </div>
                <div style="font-size: 1.2rem">{{ item.min }}</div>
              </div>
            </div>
            <div class="mt-1" style="color: #999999; font-size: 0.9rem">
              {{ $t("staking.duration") }}
            </div>
            <div class="d-flex flex-wrap">
              <div
                @click="item.checked = 0"
                :class="[
                  'mt-1',
                  'duration-box',
                  { 'duration-box-selected': item.checked === 0 },
                ]"
              >
                {{ 3 + $t("staking.day") }}
              </div>
              <div
                @click="item.checked = 1"
                :class="[
                  'mt-1',
                  'duration-box',
                  { 'duration-box-selected': item.checked === 1 },
                ]"
              >
                {{ 30 + $t("staking.day") }}
              </div>
              <div
                @click="item.checked = 2"
                :class="[
                  'mt-1',
                  'duration-box',
                  { 'duration-box-selected': item.checked === 2 },
                ]"
              >
                {{ 60 + $t("staking.day") }}
              </div>
              <div
                @click="item.checked = 3"
                :class="[
                  'mt-1',
                  'duration-box',
                  { 'duration-box-selected': item.checked === 3 },
                ]"
              >
                {{ 90 + $t("staking.day") }}
              </div>
            </div>
            <button @click="stakingButton(item)" class="mt-4 staking-button">
              {{ $t("staking.stakeNow") }}
            </button>
          </div>
        </div>
      </div>
    </v-card>
    <v-overlay class="w-full" :dark="false" :value="purchase.dialog">
      <v-card>
        <v-card-text>
          <div class="d-flex flex-column w-72">
            <div class="d-flex purchase-title">
              <div>{{ $t("staking.purchaseOrder") }}</div>
              <img
                @click="purchase.dialog = false"
                :src="closeIcon"
                style="width: 1.5rem"
                class="my-auto ml-auto"
              />
            </div>
            <div class="value-column-container">
              <div class="d-flex">
                <div>{{ $t("staking.stakingPeriod") }}</div>
                <div class="ml-auto">{{ purchase.period + " Days" }}</div>
              </div>
              <div class="d-flex mt-1">
                <div>{{ $t("staking.valueDate") }}</div>
                <div class="ml-auto">{{ valueDate }}</div>
              </div>
              <div class="d-flex mt-1">
                <div>{{ $t("staking.redemptionDate") }}</div>
                <div class="ml-auto">{{ redemptionDate }}</div>
              </div>
            </div>
            <div class="value-column-container">
              <div class="d-flex">
                <div>{{ $t("staking.managementFee") }}</div>
                <div class="ml-auto">0%</div>
              </div>
              <div class="d-flex mt-1">
                <div>{{ $t("staking.stakingAsset") }}</div>
                <div class="ml-auto">{{ purchase.coin_symbol }}</div>
              </div>
              <div class="d-flex mt-1">
                <div>{{ $t("staking.minAmount") }}</div>
                <div class="ml-auto">{{ purchase.min }}</div>
              </div>
              <div class="d-flex mt-1">
                <div>{{ $t("staking.AIR") }}</div>
                <div class="ml-auto">{{ purchase.rate + "%" }}</div>
              </div>
              <div class="d-flex flex-column value-column-container mt-1">
                <div style="color: black; font-size: 1.1rem">
                  <strong>{{ $t("staking.purchaseLot") }}</strong>
                </div>
                <div
                  class="d-flex mt-2 w-full"
                  style="
                    padding: 0.3rem;
                    border-radius: 0.3rem;
                    border: 1px solid #bfbfbf;
                  "
                >
                  <input
                    class="flex-grow-1"
                    type="text"
                    inputmode="decimal"
                    v-model="purchase.amount"
                  />
                  <div @click="purchase.amount = available">{{ $t("common.max") }}</div>
                </div>
                <div class="d-flex mt-1">
                  <div>{{ $t("staking.avblBalance") }}</div>
                  <div class="ml-auto">{{ available }}</div>
                </div>
                <div class="d-flex mt-1">
                  <div>{{ $t("staking.expectedInterest") }}</div>
                  <div class="ml-auto">{{ expectedInterest }}</div>
                </div>
                <div class="d-flex mt-1">
                  <div>{{ $t("staking.totalCost") }}</div>
                  <div class="ml-auto">
                    {{ purchase.amount + " " + purchase.coin_symbol }}
                  </div>
                </div>
              </div>
            </div>
            <button class="button-confirm" @click="confirmStakingButton">
              {{ $t("staking.confirm") }}
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-overlay>
    <v-overlay :value="isLoading">
      <v-progress-circular
        color="blue darken-4"
        indeterminate
        size="40"
      ></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import coinInfo from "../../utils/coinInfo";
import axios from "../../axios";
import { mapMutations, mapGetters } from "vuex";
export default {
  data() {
    return {
      isLoading: true,
      purchaseStaking: true,
      stakingList: [],
      purchase: {
        dialog: false,
        coin_symbol: "",
        rate: "",
        min: "",
        period: "",
        amount: 0,
      },
      closeIcon: require("../../assets/icon/close.png"),
      plusBoxIcon: require("../../assets/icon/plus-box-outline.png"),
    };
  },
  props: ["value"],
  components: {},
  computed: {
    coinInfo() {
      return coinInfo;
    },
    ...mapGetters(["userDataGetter", "cryptoIconGetter"]),
    valueDate() {
      try {
        var today = new Date();
        var date =
          today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
        return date;
      } catch {
        return 0;
      }
    },
    redemptionDate() {
      try {
        var today = new Date();
        today.setDate(today.getDate() + this.purchase.period);
        var date =
          today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
        return date;
      } catch {
        return 0;
      }
    },
    expectedInterest() {
      try {
        var times = this.purchase.period / 30;
        var monthlyRate = ((this.purchase.rate / 100) * 30) / 365;
        return (
          this.purchase.amount * Math.pow(1 + monthlyRate, times) - this.purchase.amount
        );
      } catch {
        return 0;
      }
    },
    available() {
      try {
        var max = "";
        if (this.purchase.coin_symbol != "USDT") {
          let coin = this.userDataGetter.user_coin.find(
            (element) => element.coin_symbol == this.purchase.coin_symbol + "USDT"
          );

          max = coin.amount - coin.reserved;
        } else {
          let usdt = this.userDataGetter.user_coin.find(
            (element) => element.coin_symbol == "USDT"
          );
          max = usdt.amount - usdt.reserved;
        }
        return Math.floor(max * 1000) / 1000;
      } catch (e) {
        console.log(e);
        return "0";
      }
    },
  },
  methods: {
    ...mapMutations(["snackbarSetter"]),
    fetchStakingDetail() {
      axios.get("/staking/offer").then((response) => {
        console.log(response.data);
        let data = response.data;
        let array = [];
        data.forEach((element) => {
          element.checked = 0;
          element.rate = JSON.parse(element.rate);
          array.push(element);
        });
        this.isLoading = false;
        this.stakingList = array;
      });
    },
    stakingButton(e) {
      console.log(e);
      this.purchase.coin_symbol = e.coin_symbol;
      this.purchase.rate = e.rate[e.checked];
      this.purchase.min = e.min;
      this.purchase.amount = e.min;
      this.purchase.period =
        e.checked == 0 ? 3 : e.checked == 1 ? 30 : e.checked == 2 ? 60 : 90;
      this.purchase.dialog = true;
    },
    confirmStakingButton() {
      if (this.purchase.amount * 1 < this.purchase.min * 1) {
        this.snackbarSetter({
          success: false,
          message: this.$t("staking.failedMin"),
          visible: true,
        });
      } else if (this.purchase.amount * 1 <= this.available * 1) {
        var valueDate = new Date();
        var redemptionDate = new Date();
        redemptionDate.setDate(redemptionDate.getDate() + this.purchase.period);
        this.isLoading = true;
        axios
          .post("/staking/create", {
            amount: this.purchase.amount,
            coin_symbol: this.purchase.coin_symbol,
            value_date: valueDate,
            redemption_date: redemptionDate,
            expected: this.expectedInterest,
            rate: this.purchase.rate,
            period: this.purchase.period,
          })
          .then((response) => {
            this.isLoading = false;
            if (response.data.status) {
              this.snackbarSetter({
                success: true,
                message: this.$t("staking.orderCreated"),
                visible: true,
              });
              this.purchase.dialog = false;
            } else {
              this.snackbarSetter({
                success: false,
                message: this.$t("staking.failedNotEnough"),
                visible: true,
              });
            }
          });
      } else {
        this.snackbarSetter({
          success: false,
          message: this.$t("staking.failedNotEnough"),
          visible: true,
        });
      }
    },
  },
  created() {
    this.fetchStakingDetail();
  },
  mounted() {},
};
</script>

<style scoped>
.container-staking {
  background: rgb(143, 145, 170);
  background: linear-gradient(
    90deg,
    rgba(143, 145, 170, 1) 0%,
    rgba(78, 98, 138, 1) 100%
  );
}
.container-deposit {
  touch-action: pan-y !important;
}
.container-deposit div {
  touch-action: pan-y !important;
}
.textfield-input {
  color: #000000;
  border-style: solid;
  border-width: 1px;
  border-color: #8c8c8c;
  border-radius: 0.7rem;

  padding: 0.6rem;
  font-size: 1.2rem;
}
.upload-container {
  color: #000000;
  border-style: solid;
  border-width: 1px;
  border-color: #8c8c8c;
  border-radius: 0.7rem;
  height: 10rem;
  padding: 0.6rem;
  font-size: 1rem;
}
.button-login {
  font-size: 1.2rem;
  background-color: #636689;
  color: #ffffff;
  padding: 0.4rem;
  border-radius: 0.75rem;
}
input[type="file"] {
  display: none;
}
.address-left-container {
  padding: 0.2rem 0 0.2rem 0;
  border-width: 1px;
  border-radius: 0.6rem 0 0 0.6rem;
  border-color: #3d406c;
}
.address-right-container {
  padding: 0.2rem 0 0.2rem 0;
  border-width: 1px;
  border-radius: 0 0.6rem 0.6rem 0;
  border-color: #3d406c;
}
.address-selected {
  background-color: #3d406c;
  color: white;
}
.button-login {
  font-size: 1.2rem;
  background-color: #3d406c;
  color: #ffffff;
  padding: 0.4rem;
  border-radius: 0.75rem;
}
.button-confirm {
  font-size: 1.2rem;
  background-color: #3d406c;
  color: #ffffff;
  padding: 0.4rem;
  border-radius: 0.3rem;
}
textarea:focus,
input:focus {
  outline: none;
}
.text-input-container {
  border: 1px solid #999999;
  border-radius: 0.8rem;
  font-size: 1.2rem;
  padding: 0.6rem 0.7rem 0.6rem 0.7rem;
}
.duration-box {
  border-radius: 0.5rem;
  border-style: solid;
  margin-right: 0.5rem;
  padding: 0.3rem 0.8rem 0.3rem 0.8rem;
  border-width: 1px;
  background: #ffffff;
  color: #808080;
  border-color: lightgrey;
}
.duration-box-selected {
  color: #636689;
  border-width: 2px;
  border-color: #636689;
}
.staking-button {
  background-color: #636689;
  color: #ffffff;
  padding: 0.4rem;
  border-radius: 0.3rem;
}
input[type="file"] {
  display: none;
}
.purchase-title {
  padding-bottom: 0.7rem;
  font-size: 1.3rem;
  color: black;
  font-weight: bold;
}
.value-column-container {
  border-top: 1px solid #cccccc;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
</style>
