<template>
  <v-dialog
    persistent
    style="background-color: white"
    @input="$emit('input')"
    :value="value"
  >
    <v-card class="pt-4">
      <v-card-text v-if="tab === 0">
        <div class="mx-auto d-flex flex-column w-full">
          <div
            style="border-bottom: 1px solid #cccccc"
            class="mb-1 d-flex purchase-title"
          >
            <div class="d-flex flex-column">
              <div style="font-size: 1rem; color: #737373">
                {{ $t("staking.stakingDetail.orderid") }}
              </div>
              <div style="font-size: 1rem">
                {{ "S" + staking.staking_id.toString().padStart(10, "0") }}
              </div>
            </div>
            <img
              @click="$emit('input')"
              :src="closeIcon"
              style="width: 1.5rem"
              class="my-auto ml-auto"
            />
          </div>
          <div class="my-1 d-flex">
            <div style="color: #737373">
              {{ $t("staking.stakingDetail.stakingAsset") }}
            </div>
            <div style="color: black" class="ml-auto">{{ staking.coin_symbol }}</div>
          </div>
          <div class="my-1 d-flex">
            <div style="color: #737373">{{ $t("staking.stakingDetail.amount") }}</div>
            <div style="color: black" class="ml-auto">{{ staking.amount }}</div>
          </div>
          <div class="my-1 d-flex">
            <div style="color: #737373">{{ $t("staking.stakingDetail.AIR") }}</div>
            <div style="color: black" class="ml-auto">{{ staking.rate + "%" }}</div>
          </div>
          <div class="my-1 d-flex">
            <div style="color: #737373">
              {{ $t("staking.stakingDetail.managementFee") }}
            </div>
            <div style="color: black" class="ml-auto">0%</div>
          </div>
          <div class="my-1 d-flex">
            <div style="color: #737373">
              {{ $t("staking.stakingDetail.stakingPeriod") }}
            </div>
            <div style="color: black" class="ml-auto">{{ staking.period + " Days" }}</div>
          </div>
          <div class="my-1 d-flex">
            <div style="color: #737373">{{ $t("staking.stakingDetail.valueDate") }}</div>
            <div style="color: black" class="ml-auto">
              {{ moment(staking.value_date).format("YYYY-MM-DD hh:mm:ss") }}
            </div>
          </div>
          <div class="my-1 d-flex">
            <div style="color: #737373">
              {{ $t("staking.stakingDetail.redemptionDate") }}
            </div>
            <div style="color: black" class="ml-auto">
              {{ moment(staking.redemption_date).format("YYYY-MM-DD hh:mm:ss") }}
            </div>
          </div>
          <!--    <button
            v-if="staking.status !== 'redempted'"
            @click="redeemButton"
            class="mt-16 button1-style"
          >
            {{ $t("staking.stakingDetail.redeem") }}
          </button> -->
        </div>
      </v-card-text>
      <v-card-text v-if="tab === 1">
        <div class="mx-auto d-flex flex-column w-full">
          <div style="border-bottom: 1px solid #cccccc" class="d-flex purchase-title">
            <div style="font-size: 1rem; color: #737373">
              {{ $t("staking.stakingDetail.redeemEarlier") }}
            </div>

            <img
              @click="$emit('input')"
              :src="closeIcon"
              style="width: 1.5rem"
              class="my-auto ml-auto"
            />
          </div>
          <div class="my-1 d-flex">
            <div style="color: #737373">{{ $t("staking.stakingDetail.orderid") }}</div>
            <div style="color: black" class="ml-auto">{{ staking._id }}</div>
          </div>
          <div class="my-1 d-flex">
            <div style="color: #737373">
              {{ $t("staking.stakingDetail.stakingAsset") }}
            </div>
            <div style="color: black" class="ml-auto">{{ staking.name }}</div>
          </div>
          <div class="my-1 d-flex">
            <div style="color: #737373">{{ $t("staking.stakingDetail.amount") }}</div>
            <div style="color: black" class="ml-auto">{{ staking.amount }}</div>
          </div>
          <div class="my-1 d-flex">
            <div style="color: #737373">
              {{ $t("staking.stakingDetail.receivedProfit") }}
            </div>
            <div style="color: black" class="ml-auto">{{ staking.profit }}</div>
          </div>

          <div class="my-1 d-flex">
            <div style="color: #737373">
              {{ $t("staking.stakingDetail.redemptionDate") }}
            </div>
            <div style="color: black" class="ml-auto">
              {{ todayDate }}
            </div>
          </div>
          <div style="text-align: center; font-size: 1.4rem" class="mt-4">
            {{ $t("staking.stakingDetail.redeemAmount") }}
          </div>
          <div style="color: black; text-align: center; font-size: 1.4rem" class="mt-1">
            {{ staking.amount - staking.profit }}
          </div>
          <div class="mt-2">
            {{ $t("staking.stakingDetail.redeemFormular") }}<br />
            {{ $t("staking.stakingDetail.redeemEarlierAlert") }}
          </div>
          <!-- <button @click="confirmRedeemButton" class="mt-16 button1-style">
            {{ $t("staking.stakingDetail.confirmRedeem") }}
          </button> -->
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from "vuex";
import axios from "axios";
import moment from "moment";
export default {
  data() {
    return { tab: 0, closeIcon: require("../../assets/icon/close.png") };
  },
  props: ["value", "staking"],
  components: {},
  computed: {
    moment() {
      return moment;
    },
    todayDate() {
      var today = new Date();
      var date =
        today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
      return date;
    },
  },
  methods: {
    ...mapMutations(["snackbarSetter"]),
    redeemButton() {
      if (confirm(this.$t("staking.stakingDetail.redeemEarlierAlert"))) {
        this.tab = 1;
      }
    },
    confirmRedeemButton() {
      axios({
        method: "POST",
        url: process.env.VUE_APP_BACKENDAPI + "staking/redeemOrder",
        data: {
          _id: this.staking._id,
        },
      }).then(
        (response) => {
          if (response) {
            this.$emit("input");
            this.snackbarSetter({
              success: true,
              message: this.$t("common.success"),
              visible: true,
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
  created() {},

  mounted() {},
};
</script>

<style scoped>
.purchase-title {
  padding-bottom: 0.4rem;
  font-size: 1.3rem;
  color: black;
  font-weight: bold;
}
.button1-style {
  background-color: #636689;
  color: #ffffff;

  padding: 0.6rem;
  border-radius: 0.4rem;

  font-size: 1.2rem;
  text-align: center;
}
</style>
