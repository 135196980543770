<template>
  <div
    class="d-flex flex-column"
    style="background-color: #636689; overflow-y: scroll; height: 100%"
  >
    <div class="p-4">
      <div style="font-size: 1rem; color: #c4c5d2">
        {{ $t("account.totalValueUSDT") }}
      </div>
      <div style="font-size: 1.6rem; color: white">
        <strong>{{ "$" + myAccountTotal.toFixed(4) }}</strong>
      </div>
    </div>
    <div class="wallet-container">
      <div class="d-flex px-4 py-3">
        <div
          @click="tab = 0"
          :class="['w-1/2', 'text-center', 'pb-2', { 'wallet-tab-selected': tab === 0 }]"
        >
          {{ $t("account.spotWallet") }}
        </div>
        <div
          @click="tab = 1"
          :class="['w-1/2', 'text-center', 'pb-2', { 'wallet-tab-selected': tab === 1 }]"
        >
          {{ $t("account.stakingWallet") }}
        </div>
      </div>
      <div class="px-4" v-if="tab === 0">
        <div style="color: #808080; font-size: 1.1rem" class="mt-3">
          {{ $t("account.spotWalletValueUSDT") }}
        </div>
        <div style="color: #595959; font-size: 1.3rem">
          <strong>{{ "$" + spotWalletTotal.toFixed(4) }}</strong>
        </div>
        <div style="color: #808080; font-size: 0.8rem">
          {{ $t("account.pl24h") }}
        </div>
        <div class="d-flex">
          <div
            :class="[
              { assetUp: get24hChangeValue > 0 },
              { assetDown: get24hChangeValue < 0 },
            ]"
          >
            {{ (get24hChangeValue > 0 ? "+" : "") + get24hChangeValue.toFixed(4)
            }}{{ " / "
            }}{{
              (get24hChangePercent > 0 ? "+" : "") + get24hChangePercent.toFixed(2) + "%"
            }}
          </div>
        </div>
        <div class="d-flex mt-2">
          <button @click="depositButton" class="w-1/3 button1-style">
            {{ $t("account.deposit") }}
          </button>
          <button @click="withdrawalButton" class="w-1/3 button2-style">
            {{ $t("account.withdrawal") }}
          </button>
          <button @click="orderHistoryButton" class="w-1/3 button2-style">
            {{ $t("account.orderHistory") }}
          </button>
        </div>
        <div class="d-flex">
          <div class="w-1/2 my-4">
            <GChart
              class="mx-auto my-auto"
              style="width: 100%"
              :settings="{ packages: ['corechart'] }"
              :data="chartData"
              type="PieChart"
              :options="chartOptions"
            />
          </div>
          <div class="w-1/2 my-auto d-flex flex-column">
            <div v-for="(item, index) in chartData" :key="index">
              <div v-if="index > 0">
                <div class="d-flex my-auto mt-2">
                  <div
                    class="my-auto"
                    :style="{
                      width: '0.5rem',
                      height: '0.5rem',
                      backgroundColor: chartOptions.colors[index - 1],
                    }"
                  ></div>
                  <div class="ml-2 my-auto">{{ item[0] + ", " + item[1] + "%" }}</div>
                </div>
                <div>{{ "$" + item[2].toFixed(2) }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column">
          <div style="font-size: 25px">Assets</div>
          <div class="d-flex p-1 mt-2">
            <div class="my-auto mr-4">
              <img
                style="width: 30px; object-fit: contain"
                :src="require('../../assets/c-icon/USDT.png')"
              />
            </div>
            <div class="d-flex flex-column">
              <div style="color: #595959; font-size: 1.1rem">
                {{ "USDT" }}
              </div>
              <div style="color: #a6a6a6; font-size: 0.8rem">
                {{ "Tether" }}
              </div>
            </div>
            <div class="d-flex flex-column ml-auto">
              <div style="text-align: right; color: #595959; font-size: 1.1rem">
                {{
                  userDataGetter.user_coin
                    .find((element) => element.coin_symbol == "USDT")
                    .amount.toFixed(4)
                }}
              </div>
              <div style="text-align: right; color: #a6a6a6; font-size: 0.8rem">
                {{
                  userDataGetter.user_coin
                    .find((element) => element.coin_symbol == "USDT")
                    .amount.toFixed(4)
                }}
              </div>
            </div>
          </div>
          <div
            class="d-flex p-1"
            style="border-top: 1px solid #aeaeae"
            v-for="(item, index) in userFilteredCoins.filter(
              (element) => element.coin_symbol != 'USDT'
            )"
            :key="index"
          >
            <div class="my-auto mr-4">
              <img
                style="width: 30px; object-fit: contain"
                :src="coinInfo[item.coin_symbol].img"
              />
            </div>
            <div class="d-flex flex-column">
              <div style="color: #595959; font-size: 1.1rem">
                {{ coinInfo[item.coin_symbol].symbol }}
              </div>
              <div style="color: #a6a6a6; font-size: 0.8rem">
                {{ coinInfo[item.coin_symbol].name }}
              </div>
            </div>
            <div class="d-flex flex-column ml-auto">
              <div style="text-align: right; color: #595959; font-size: 1.1rem">
                {{ item.amount.toFixed(4) }}
              </div>
              <div style="text-align: right; color: #a6a6a6; font-size: 0.8rem">
                {{ "$" + item.value.toFixed(4) }}
              </div>
            </div>
          </div>
        </div>
        <div style="height: 5rem"></div>
      </div>

      <div v-if="tab === 1">
        <div class="px-4">
          <div style="color: #808080; font-size: 1.1rem" class="mt-3">
            {{ $t("account.stakingWallet") }}
          </div>
          <div style="color: #595959; font-size: 1.3rem">
            <strong>{{ "$" + stakingWalletTotal.toFixed(4) }}</strong>
          </div>
          <div style="color: #808080; font-size: 0.8rem">
            {{ $t("account.totalProfitUSDT") }}
          </div>
          <div class="d-flex">
            <div class="assetUp">
              {{ "$" + getTotalStakingProfit.toFixed(4) }}
            </div>
          </div>
          <button @click="purchaseButton" class="mt-4 w-full button1-style">
            {{ $t("account.purchase") }}
          </button>
          <button @click="stakingHistoryButton" class="mb-4 w-full button2-style">
            {{ $t("account.history") }}
          </button>
        </div>
        <div class="w-full" style="background-color: #f2f2f2; height: 1.2rem"></div>
        <div class="d-flex flex-column">
          <div style="border-bottom: 1px solid #e6e6e6" class="w-full py-2 text-center">
            {{ $t("account.holdings") }}
          </div>
        </div>
        <div
          class="d-flex flex-column p-3"
          v-for="(item, index) in userDataGetter.user_staking"
          :key="index"
          style="border-bottom: 1px solid #e6e6e6"
          @click="() => onStakingItemClick(item)"
        >
          <div class="d-flex">
            <img
              style="height: 1.5rem"
              class="my-auto"
              :src="
                item.coin_symbol == 'USDT'
                  ? coinInfo[item.coin_symbol].img
                  : coinInfo[item.coin_symbol + 'USDT'].img
              "
            />
            <div style="font-size: 1.2rem" class="my-auto ml-2">
              {{ item.coin_symbol }}
            </div>
            <div style="font-size: 0.8rem" class="my-auto ml-auto">
              {{ moment(item.value_date).format("YYYY-MM-DD hh:mm:ss") }}
            </div>
          </div>
          <div style="font-size: 0.9rem" class="mt-2">
            {{ `${$t("account.period")}: ${item.period} Days` }}
          </div>
          <div style="font-size: 0.9rem">
            {{ `${$t("account.amount")} (${item.coin_symbol}): ${item.amount}` }}
          </div>
          <div style="font-size: 0.9rem">
            {{ `${$t("account.cumulativeInterest")}: ${item.profit} ` }}
          </div>
        </div>
        <div style="height: 20rem"></div>
      </div>
    </div>
    <Withdrawal v-if="dialog.withdrawal" v-model="dialog.withdrawal" />
    <Deposit v-if="dialog.deposit" v-model="dialog.deposit" />
    <StakingDetail
      v-if="dialog.stakingDetail"
      v-model="dialog.stakingDetail"
      :staking="selectedStaking"
    />
    <StakingHistory v-if="dialog.stakingHistory" v-model="dialog.stakingHistory" />
    <OrderHistory v-if="dialog.orderHistory" v-model="dialog.orderHistory" />
    <Staking v-if="dialog.staking" v-model="dialog.staking" />
  </div>
</template>

<script>
import moment from "moment";
import coinInfo from "../../utils/coinInfo";
import { mapGetters } from "vuex";
import { GChart } from "vue-google-charts";
import Deposit from "../deposit/index.vue";
import Withdrawal from "../withdrawal/index.vue";
import Staking from "../staking/index.vue";
import StakingHistory from "../staking/StakingHistory.vue";
import StakingDetail from "./StakingDetail.vue";
import OrderHistory from "./OrderHistory.vue";
/*



import Staking from "../staking/index.vue";

 */
export default {
  data() {
    return {
      eyeOff: false,
      tab: 0,
      chartItems: [],
      total: 0,
      chartData: [],
      chartOptions: {
        pieHole: 0.6,
        legend: "none",
        pieSliceText: "none",
        fontSize: 0,
        width: 170,
        height: 170,
        chartArea: { width: "85%", height: "85%" },
        colors: ["#fd7f6f", "#7eb0d5", "#b2e061", "#bd7ebe"],
        enableInteractivity: false,
      },
      dialog: {
        deposit: false,
        withdrawal: false,
        orderHistory: false,
        stakingDetail: false,
        staking: false,
        stakingHistory: false,
      },

      selectedStaking: {},
    };
  },
  components: {
    GChart,
    Deposit,
    Withdrawal,
    StakingHistory,
    StakingDetail,
    OrderHistory,
    Staking,
    /*
    StakingHistory,
    Staking,

    OrderHistory,
    StakingDetail, */
  },
  computed: {
    moment() {
      return moment;
    },
    ...mapGetters([
      "userDataGetter",
      "stakingDataGetter",
      "cryptoIconGetter",
      "coinDataGetter",
    ]),
    coinInfo() {
      return coinInfo;
    },
    userFilteredCoins() {
      try {
        let filteredCoins = this.userDataGetter.user_coin.filter(
          (coin) => coin.amount > 0
        );
        filteredCoins.forEach((coin, index) => {
          if (coin.coin_symbol == "USDT") {
            filteredCoins[index].value = coin.amount;
          } else {
            let coinPrice = this.coinDataGetter.find(
              (element) => element.coin_symbol == coin.coin_symbol
            );

            filteredCoins[index].value = coinPrice.current_price * coin.amount;
          }
        });
        filteredCoins.sort((a, b) => {
          return b.value - a.value;
        });

        return filteredCoins;
      } catch (e) {
        console.log(e);
        return [];
      }
    },
    spotWalletTotal() {
      try {
        let total = 0;
        //spot
        this.userDataGetter.user_coin.forEach((coin) => {
          if (coin.coin_symbol == "USDT") {
            total = total + coin.amount;
          } else {
            coin.coin_symbol
            let coinPrice = this.coinDataGetter.find(
              (element) => element.coin_symbol == coin.coin_symbol
            );
            console.log(coinPrice)
            total = total * 1 + coinPrice.current_price * coin.amount;
          }
        });
        return total;
      } catch (e) {
        console.log(e);
        return 0;
      }
    },
    stakingWalletTotal() {
      try {
        let total = 0;

        this.userDataGetter.user_staking.forEach((staking) => {
          if (staking.coin_symbol != "USDT") {
            let coinPrice = this.coinDataGetter.find(
              (element) => element.coin_symbol == staking.coin_symbol + "USDT"
            );
            total = total * 1 + coinPrice.current_price * staking.amount;
          } else {
            total = total * 1 + staking.amount * 1;
          }
        });

        return total;
      } catch (e) {
        console.log(e);
        return 0;
      }
    },
    myAccountTotal() {
      try {
        return this.stakingWalletTotal * 1 + this.spotWalletTotal * 1;
      } catch (e) {
        return 0;
      }
    },
    get24hChangeValue() {
      try {
        let total = 0;
        this.userDataGetter.user_coin.forEach((coin) => {
          if (coin.coin_symbol == "USDT") {
            total = total * 1 + coin.amount;
          } else {
            let coinPrice = this.coinDataGetter.find(
              (element) => element.coin_symbol == coin.coin_symbol
            );
            total = total * 1 + coinPrice.current_price * coin.amount;
          }
        });
        let asset24h = this.userDataGetter.user_asset_history[23].amount;

        return total * 1 - asset24h * 1;
      } catch {
        return 0;
      }
    },
    get24hChangePercent() {
      try {
        let total = 0;
        this.userDataGetter.user_coin.forEach((coin) => {
          if (coin.coin_symbol == "USDT") {
            total = total * 1 + coin.amount;
          } else {
            let coinPrice = this.coinDataGetter.find(
              (element) => element.coin_symbol == coin.coin_symbol
            );
            total = total * 1 + coinPrice.current_price * coin.amount;
          }
        });
        let asset24h = this.userDataGetter.user_asset_history[23].amount;
        let percent = ((total * 1 - asset24h * 1) * 100) / asset24h;
        if (isNaN(percent)) {
          return 0;
        } else {
          return percent;
        }
      } catch {
        return 0;
      }
    },
    getTotalStakingProfit() {
      try {
        let total = 0;
        this.userDataGetter.user_staking.forEach((staking) => {
          if (staking.coin_symbol != "USDT") {
            let coinPrice = this.coinDataGetter.find(
              (element) => element.coin_symbol == staking.coin_symbol + "USDT"
            );
            total = total * 1 + coinPrice.current_price * staking.profit;
          } else {
            total = total * 1 + staking.profit;
          }
        });

        return total;
      } catch (e) {
        console.log(e);
        return 0;
      }
    },
  },
  methods: {
    stakingHistoryButton() {
      this.dialog.stakingHistory = true;
    },
    purchaseButton() {
      this.dialog.staking = true;
    },
    orderHistoryButton() {
      this.dialog.orderHistory = true;
    },
    withdrawalButton() {
      this.dialog.withdrawal = true;
    },
    depositButton() {
      this.dialog.deposit = true;
    },
    onStakingItemClick(item) {
      this.selectedStaking = item;
      this.dialog.stakingDetail = true;
    },
    loadChartData() {
      try {
        let array = [];
        array.push(["coin", "value", "realValue"]);
        let total = 0;
        let chartValue = 0;
        this.userFilteredCoins.forEach((coin) => {
          if (coin.coin_symbol == "USDT") {
            total = total + coin.amount;
          } else {
            let coinPrice = this.coinDataGetter.find(
              (element) => element.coin_symbol == coin.coin_symbol
            );
            total = total * 1 + coinPrice.current_price * coin.amount;
          }
        });
        let usdt = this.userDataGetter.user_coin.find(
          (element) => element.coin_symbol == "USDT"
        ).amount;

        if (this.userFilteredCoins.length != 0) {
          chartValue = Math.floor((usdt * 100 * 10) / total) / 10;

          array.push(["USDT", chartValue, usdt]);
        } else if (usdt == 0) {
          chartValue = 100;

          array.push(["USDT", chartValue, usdt]);
        } else {
          chartValue = 100;

          array.push(["USDT", chartValue, usdt]);
        }

        if (this.userFilteredCoins.length < 4) {
          this.userFilteredCoins.forEach((element) => {
            if (element.coin_symbol == "USDT") {
              return;
            }
            chartValue = Math.floor((element.value * 100 * 10) / total) / 10;
            array.push([element.coin_symbol, chartValue, element.value]);
          });
        } else {
          var other = 0;
          this.userFilteredCoins.forEach((element, index) => {
            if (element.coin_symbol == "USDT") {
              return;
            }
            if (index < 2) {
              chartValue = Math.floor((element.value * 100 * 10) / total) / 10;
              array.push([element.coin_symbol.slice(0, -4), chartValue, element.value]);
            } else {
              other = other * 1 + element.value * 1;
            }
          });
          chartValue = Math.floor((other * 100 * 10) / total) / 10;

          array.push(["Others", chartValue, other]);
        }

        this.chartData = array;
      } catch {
        this.chartData = [];
      }
    },
  },
  created() {
    this.loadChartData();
  },

  mounted() {},
};
</script>
<style scoped>
.wallet-tab-selected {
  border-bottom: 2px solid #05131f;
  color: #05131f;
}
.wallet-container {
  background-color: white;

  color: #737373;
  border-radius: 1rem 1rem 0 0;
}
.assetDown {
  color: #f7445d;
}
.assetUp {
  color: #30bb87;
}
.button1-style {
  background-color: #636689;
  color: #ffffff;

  padding: 0.4rem;
  border-radius: 0.4rem;
  margin: 0.3rem;
  font-size: 0.9rem;
  text-align: center;
}
.button2-style {
  background-color: #ebebf0;
  color: #636689;

  padding: 0.4rem;
  border-radius: 0.4rem;
  margin: 0.2rem;
  font-size: 0.9rem;
  text-align: center;
}
</style>
