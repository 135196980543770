var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-home"},[_c('div',{staticClass:"container-inner pt-1 pb-4",staticStyle:{"background-color":"#242640"}},[_c('v-carousel',{staticClass:"carousel-border",attrs:{"cycle":"","height":"140","hide-delimiter-background":"","show-arrows-on-hover":""}},_vm._l((_vm.carousel),function(item,i){return _c('v-carousel-item',{key:i,staticClass:"carousel-border",attrs:{"src":item}})}),1)],1),_c('div',{staticStyle:{"position":"relative","background-color":"#f1f0f0"}},[_c('div',{staticClass:"w-full",staticStyle:{"position":"absolute","top":"0px","background-color":"#242640","height":"50px"}}),_c('div',{staticClass:"market-card-relative"},[_c('div',{staticClass:"text-market-title mb-2"},[_vm._v(_vm._s(_vm.$t("home.market")))]),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"text-market-coin-title"},[_vm._v("BTC/USDT")]),_c('div',{staticClass:"d-flex mt-1"},[_c('img',{staticClass:"my-auto",staticStyle:{"width":"30px","object-fit":"contain"},attrs:{"src":require('../../assets/crypto-icon/btc.png')}}),_c('div',{staticClass:"ml-2 d-flex flex-column"},[_c('div',{class:[
                  'text-market-coin',
                  _vm.coinBTCGetter.change_pct_24h > 0
                    ? 'text-price-up'
                    : 'text-price-down',
                ]},[_vm._v(" "+_vm._s(_vm.coinBTCGetter.change_pct_24h > 0 ? "+" : "")+_vm._s(_vm.coinBTCGetter.change_pct_24h.toFixed(2) + "%")+" ")]),_c('div',{class:[
                  'text-market-value',
                  'overflow-hidden',
                  _vm.coinBTCGetter.change_pct_24h > 0
                    ? 'text-price-up'
                    : 'text-price-down',
                ]},[_vm._v(" "+_vm._s(_vm.coinBTCGetter.current_price.toFixed(2))+" ")])])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"text-market-coin-title"},[_vm._v("ETH/USDT")]),_c('div',{staticClass:"d-flex mt-1"},[_c('img',{staticClass:"my-auto",staticStyle:{"width":"30px","object-fit":"contain"},attrs:{"src":require('../../assets/crypto-icon/eth.png')}}),_c('div',{staticClass:"ml-2 d-flex flex-column"},[_c('div',{class:[
                  'text-market-coin',

                  _vm.coinETHGetter.change_pct_24h > 0
                    ? 'text-price-up'
                    : 'text-price-down',
                ]},[_vm._v(" "+_vm._s(_vm.coinETHGetter.change_pct_24h > 0 ? "+" : "")+_vm._s(_vm.coinETHGetter.change_pct_24h.toFixed(2) + "%")+" ")]),_c('div',{class:[
                  'text-market-value',
                  'overflow-hidden',
                  _vm.coinETHGetter.change_pct_24h > 0
                    ? 'text-price-up'
                    : 'text-price-down',
                ]},[_vm._v(" "+_vm._s(_vm.coinETHGetter.current_price.toFixed(2))+" ")])])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"text-market-coin-title"},[_vm._v("BNB/USDT")]),_c('div',{staticClass:"d-flex mt-1"},[_c('img',{staticClass:"my-auto",staticStyle:{"width":"30px","object-fit":"contain"},attrs:{"src":require('../../assets/crypto-icon/bnb.png')}}),_c('div',{staticClass:"ml-2 d-flex flex-column"},[_c('div',{class:[
                  'text-market-coin',
                  _vm.coinBNBGetter.change_pct_24h > 0
                    ? 'text-price-up'
                    : 'text-price-down',
                ]},[_vm._v(" "+_vm._s(_vm.coinBNBGetter.change_pct_24h > 0 ? "+" : "")+_vm._s(_vm.coinBNBGetter.change_pct_24h.toFixed(2) + "%")+" ")]),_c('div',{class:[
                  'text-market-value',
                  'overflow-hidden',
                  _vm.coinBNBGetter.change_pct_24h > 0
                    ? 'text-price-up'
                    : 'text-price-down',
                ]},[_vm._v(" "+_vm._s(_vm.coinBNBGetter.current_price.toFixed(2))+" ")])])])])],1)],1)]),_c('div',{staticClass:"container-inner py-4",staticStyle:{"background-color":"#f1f1f1"}},[(_vm.authGetter.isLoggedIn)?_c('v-row',{staticClass:"mx-auto",staticStyle:{"width":"99%","margin-top":"10px","margin-bottom":"18px"},attrs:{"no-gutters":""}},_vm._l((_vm.buttonList),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"3"}},[_c('div',{staticClass:"d-flex flex-column my-auto",on:{"click":item.onClick}},[_c('img',{staticClass:"mx-auto",staticStyle:{"height":"30px"},attrs:{"src":item.icon}}),_c('div',{staticClass:"mx-auto mt-1",staticStyle:{"font-size":"13px","color":"#4d4d4d"}},[_vm._v(" "+_vm._s(_vm.$t(`home.${item.key}`))+" ")])])])}),1):_vm._e(),(_vm.authGetter.isLoggedIn)?_c('div',{staticClass:"deposit-card d-flex",on:{"click":_vm.onDepositClick}},[_c('div',{staticClass:"ml-4 my-auto d-flex flex-column"},[_c('div',{staticClass:"deposit-text-title"},[_vm._v(_vm._s(_vm.$t("home.depositBox.name")))]),_c('div',{staticClass:"deposit-text-subtitle"},[_vm._v(_vm._s(_vm.$t("home.depositBox.content")))])]),_c('v-img',{staticClass:"ml-auto",attrs:{"contain":"","max-width":"70","src":_vm.depositIcon}})],1):_c('div',{staticClass:"deposit-card d-flex",on:{"click":_vm.onLoginClick}},[_c('div',{staticClass:"ml-4 my-auto d-flex flex-column"},[_c('div',{staticClass:"deposit-text-title"},[_vm._v("Login")]),_c('div',{staticClass:"deposit-text-subtitle"},[_vm._v(_vm._s(_vm.$t("home.depositBox.content")))])]),_c('v-img',{staticClass:"ml-auto",attrs:{"contain":"","max-width":"70","src":require('../../assets/login.png')}})],1),_c('div',{staticClass:"ranking-card mt-4"},[_c('div',{staticClass:"ranking-text-title"},[_vm._v(_vm._s(_vm.$t("home.ranking")))]),_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex mt-1"},[_c('div',{class:[
              'mx-1',
              'ranking-tab',
              { 'ranking-tab-selected': _vm.rankingTabIndex === 0 },
            ],on:{"click":function($event){_vm.rankingTabIndex = 0}}},[_vm._v(" "+_vm._s(_vm.$t("home.gainer"))+" ")]),_c('div',{class:[
              'mx-1',
              'ranking-tab',
              { 'ranking-tab-selected': _vm.rankingTabIndex === 1 },
            ],on:{"click":function($event){_vm.rankingTabIndex = 1}}},[_vm._v(" "+_vm._s(_vm.$t("home.loser"))+" ")])]),_c('v-row',{staticClass:"mt-2",staticStyle:{"font-size":"0.9rem","color":"#737373"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_vm._v(_vm._s(_vm.$t("home.symbol/vol")))]),_c('v-col',{attrs:{"cols":"4"}},[_vm._v(_vm._s(_vm.$t("home.latestPrice")))]),_c('v-col',{attrs:{"align":"end","cols":"4"}},[_vm._v(_vm._s(_vm.$t("home.24h")))])],1),_vm._l((_vm.rankingTabIndex === 0
            ? _vm.coinDataGainerGetter
            : _vm.coinDataLoserGetter),function(item,index){return _c('v-row',{key:index,staticClass:"my-1",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"4"}},[_c('div',{staticClass:"my-auto d-flex flex-column"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mt-auto text-symbol-head"},[_vm._v(_vm._s(item.symbol_head))]),_c('div',{staticClass:"mt-auto ml-1 text-symbol-end"},[_vm._v(_vm._s(item.symbol_end))])]),_c('div',{staticClass:"text-vol"},[_vm._v(" "+_vm._s("Vol " + (item.vol_usd_24h / 1000000).toFixed(1) + "M")+" ")])])]),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"4"}},[_c('div',{staticClass:"my-auto d-flex flex-column"},[_c('div',{class:[
                  'text-coin-change',
                  item.change_pct_24h >= 0 ? 'text-price-up' : 'text-price-down',
                ]},[_vm._v(" "+_vm._s(item.change_24h.toPrecision(4))+" ")]),_c('div',{staticClass:"text-coin-current-price"},[_vm._v(" "+_vm._s("$" + item.current_price.toPrecision(4))+" ")])])]),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"4"}},[_c('div',{class:[
                'my-auto',
                'ml-auto',
                'container-price-percentage',
                item.change_pct_24h >= 0
                  ? 'container-price-percentage-up'
                  : 'container-price-percentage-down',
              ]},[_vm._v(" "+_vm._s((item.change_pct_24h > 0 ? "+" : "") + item.change_pct_24h.toFixed(2) + "%")+" ")])])],1)})],2)])],1),(_vm.dialog.deposit)?_c('Deposit',{model:{value:(_vm.dialog.deposit),callback:function ($$v) {_vm.$set(_vm.dialog, "deposit", $$v)},expression:"dialog.deposit"}}):_vm._e(),(_vm.dialog.fundDetail)?_c('FundDetail',{model:{value:(_vm.dialog.fundDetail),callback:function ($$v) {_vm.$set(_vm.dialog, "fundDetail", $$v)},expression:"dialog.fundDetail"}}):_vm._e(),(_vm.dialog.staking)?_c('Staking',{model:{value:(_vm.dialog.staking),callback:function ($$v) {_vm.$set(_vm.dialog, "staking", $$v)},expression:"dialog.staking"}}):_vm._e(),(_vm.dialog.referral)?_c('Referral',{attrs:{"referralCode":_vm.userDataGetter.referralCode},model:{value:(_vm.dialog.referral),callback:function ($$v) {_vm.$set(_vm.dialog, "referral", $$v)},expression:"dialog.referral"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }