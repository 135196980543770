<template>
  <v-dialog
    fullscreen
    persistent
    style="background-color: white"
    @input="$emit('input')"
    :value="value"
  >
    <v-card>
      <v-toolbar style="position: sticky; top: 0" dark color="#3d406c">
        <v-btn icon @click="$emit('input')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("order.title") }}</v-toolbar-title>
      </v-toolbar>
      <div class="d-flex mt-2 mx-1" style="font-size: 0.9rem; color: #a6a6a6">
        <div style="width: 19%">{{ $t("order.action") }}</div>
        <div style="width: 19%; text-align: center">{{ $t("order.symbol") }}</div>
        <div style="width: 24%; text-align: center">{{ $t("order.AmtPr") }}</div>
        <div style="width: 24%; text-align: right">{{ $t("order.date") }}</div>
        <div style="width: 14%"></div>
      </div>
      <div class="mx-1 my-2 d-flex" v-for="(item, index) in order" :key="index">
        <div style="width: 19%" class="d-flex flex-column">
          <div style="font-size: 1.1rem; color: #999999">
            {{ $t(`order.${item.type}`) }}
          </div>
          <div
            :class="[
              { successContainer: item.status === 'success' },
              { cancelContainer: item.status === 'canceled' },
              { pendingContainer: item.status === 'pending' },
            ]"
            style="width: 4rem; text-align: center; font-size: 0.8rem"
          >
            {{ $t(`order.${item.status}`) }}
          </div>
        </div>
        <div class="my-auto d-flex" style="width: 19%">
          <div class="mx-auto" style="font-size: 14px; color: #595959">
            {{ item.coin_symbol }}
          </div>
        </div>
        <div class="my-auto d-flex flex-column" style="width: 24%">
          <div
            class="mx-auto"
            style="
              width: 100%;
              text-align: center;
              font-size: 0.9rem;
              color: #595959;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            "
          >
            {{ item.amount }}
          </div>
          <div
            class="mx-auto"
            style="
              font-size: 0.7rem;
              color: #999999;
              text-align: center;
              font-size: 0.9rem;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            "
          >
            {{ item.target_price }}
          </div>
        </div>
        <div class="my-auto d-flex flex-column" style="width: 24%; text-align: right">
          <div style="font-size: 0.9rem; color: #595959">
            {{ moment(item.created_at).format("YYYY-MM-DD") }}
          </div>
          <div style="font-size: 0.7rem; color: #999999">
            {{ moment(item.created_at).format("hh:mm:ss") }}
          </div>
        </div>
        <div class="d-flex" style="width: 14%">
          <img
            class="mx-auto my-auto"
            style="width: 1.5rem"
            @click="cancelOrder(item.order_id, item.status)"
            :src="
              item.status === 'success'
                ? checkCircleIcon
                : item.status === 'canceled'
                ? minusCircleIcon
                : item.status === 'pending'
                ? closeOrderIcon
                : ''
            "
          />
        </div>
      </div>
    </v-card>
    <v-overlay :value="isLoading">
      <v-progress-circular
        color="blue darken-4"
        indeterminate
        size="40"
      ></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import moment from "moment";
import axios from "axios";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      isLoading: true,
      fundDetail: [],
      order: [],
      type: {
        buy: "Buy",
        sell: "Sell",
        canceled: "Canceled",
        success: "Success",
        pending: "Pending",
      },
      checkCircleIcon: require("../../assets/icon/check-circle-outline.png"),
      minusCircleIcon: require("../../assets/icon/minus-circle-outline.png"),
      closeOrderIcon: require("../../assets/icon/close-order.png"),
    };
  },
  props: ["value"],
  components: {},
  computed: {
    moment() {
      return moment;
    },
  },
  methods: {
    ...mapMutations(["snackbarSetter"]),
    fetchOrder() {
      axios({
        method: "GET",
        url: "/order/allOrders",
      }).then(
        (response) => {
          var data = response.data;
          this.isLoading = false;
          data.reverse();
          this.order = data;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    cancelOrder(id, status) {
      if (status == "pending") {
        if (confirm("Do you want to cancel the order")) {
          this.isLoading = true;
          axios
            .post("/order/cancel", {
              order_id: id,
            })
            .then(
              (response) => {
                if (response) {
                  this.isLoading = false;
                  this.fetchOrder();
                }
                this.snackbarSetter({
                  success: true,
                  message: "canceled",
                  visible: true,
                });
              },
              (error) => {
                this.isLoading = false;
                console.log(error);
              }
            );
        }
      }
    },
  },
  created() {
    this.fetchOrder();
  },
  mounted() {},
};
</script>

<style scoped>
.container-deposit {
  touch-action: pan-y !important;
}
.container-deposit div {
  touch-action: pan-y !important;
}
.textfield-input {
  color: #000000;
  border-style: solid;
  border-width: 1px;
  border-color: #8c8c8c;
  border-radius: 0.7rem;

  padding: 0.6rem;
  font-size: 1.2rem;
}
.upload-container {
  color: #000000;
  border-style: solid;
  border-width: 1px;
  border-color: #8c8c8c;
  border-radius: 0.7rem;
  height: 10rem;
  padding: 0.6rem;
  font-size: 1rem;
}
.button-login {
  font-size: 1.2rem;
  background-color: #3d406c;
  color: #ffffff;
  padding: 0.4rem;
  border-radius: 0.75rem;
}
input[type="file"] {
  display: none;
}
.address-left-container {
  padding: 0.2rem 0 0.2rem 0;
  border-width: 1px;
  border-radius: 0.6rem 0 0 0.6rem;
  border-color: #3d406c;
}
.address-right-container {
  padding: 0.2rem 0 0.2rem 0;
  border-width: 1px;
  border-radius: 0 0.6rem 0.6rem 0;
  border-color: #3d406c;
}
.address-selected {
  background-color: #3d406c;
  color: white;
}
.button-login {
  font-size: 1.2rem;
  background-color: #3d406c;
  color: #ffffff;
  padding: 0.4rem;
  border-radius: 0.75rem;
}
textarea:focus,
input:focus {
  outline: none;
}
.text-input-container {
  border: 1px solid #999999;
  border-radius: 0.8rem;
  font-size: 1.2rem;
  padding: 0.6rem 0.7rem 0.6rem 0.7rem;
}
input[type="file"] {
  display: none;
}
.fundPositive {
  color: #30bb87;
}
.fundNegative {
  color: #f7445d;
}
.successContainer {
  background-color: #30bb87;
  width: auto;
  color: #ffffff;
  padding: 0.2rem;
  border-radius: 0.3rem;
}
.cancelContainer {
  background-color: #f7445d;
  width: auto;
  color: #ffffff;
  padding: 0.2rem;
  border-radius: 0.3rem;
}
.pendingContainer {
  background-color: #a6a6a6;
  width: auto;
  color: #ffffff;
  padding: 0.2rem;
  border-radius: 0.3rem;
}
</style>
