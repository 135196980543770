<template>
  <v-dialog
    fullscreen
    persistent
    style="background-color: white"
    @input="$emit('input')"
    :value="value"
  >
    <v-card>
      <v-toolbar style="position: sticky; top: 0" dark color="#3d406c">
        <v-btn icon @click="$emit('input')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("trade.selectCoin.title") }}</v-toolbar-title>
      </v-toolbar>
      <div class="d-flex flex-column mt-1 mx-1" style="font-size: 0.9rem; color: #a6a6a6">
        <div
          @click="onCoinClick(item.s)"
          style="
            padding: 0.5rem 0.7rem 0.5rem 0.7rem;
            color: #737373;
            font-size: 1.3rem;
            border-bottom-width: 1px;
            border-color: #d9d9d9;
          "
          class="d-flex"
          v-for="(item, index) in coinDataGetter"
          :key="index"
        >
          <div class="my-auto">
            {{ item.s }}
          </div>
          <img class="my-auto ml-auto" style="height: 2.5rem" :src="src.rightArrow" />
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
//import axios from "axios";
import { mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      src: {
        rightArrow: require("../../assets/icon/chevron-right.png"),
      },
    };
  },
  props: ["value"],
  components: {},
  computed: { ...mapGetters(["coinDataGetter"]) },
  methods: {
    ...mapMutations(["tradeSymbolSetter"]),
    onCoinClick(s) {
      this.tradeSymbolSetter(s);
      this.$emit("input");
    },
  },
  created() {},
  mounted() {
  },
};
</script>

<style scoped>
.container-deposit {
  touch-action: pan-y !important;
}
.container-deposit div {
  touch-action: pan-y !important;
}
.textfield-input {
  color: #000000;
  border-style: solid;
  border-width: 1px;
  border-color: #8c8c8c;
  border-radius: 0.7rem;

  padding: 0.6rem;
  font-size: 1.2rem;
}
.upload-container {
  color: #000000;
  border-style: solid;
  border-width: 1px;
  border-color: #8c8c8c;
  border-radius: 0.7rem;
  height: 10rem;
  padding: 0.6rem;
  font-size: 1rem;
}
.button-login {
  font-size: 1.2rem;
  background-color: #3d406c;
  color: #ffffff;
  padding: 0.4rem;
  border-radius: 0.75rem;
}
input[type="file"] {
  display: none;
}
.address-left-container {
  padding: 0.2rem 0 0.2rem 0;
  border-width: 1px;
  border-radius: 0.6rem 0 0 0.6rem;
  border-color: #3d406c;
}
.address-right-container {
  padding: 0.2rem 0 0.2rem 0;
  border-width: 1px;
  border-radius: 0 0.6rem 0.6rem 0;
  border-color: #3d406c;
}
.address-selected {
  background-color: #3d406c;
  color: white;
}
.button-login {
  font-size: 1.2rem;
  background-color: #3d406c;
  color: #ffffff;
  padding: 0.4rem;
  border-radius: 0.75rem;
}
textarea:focus,
input:focus {
  outline: none;
}
.text-input-container {
  border: 1px solid #999999;
  border-radius: 0.8rem;
  font-size: 1.2rem;
  padding: 0.6rem 0.7rem 0.6rem 0.7rem;
}
input[type="file"] {
  display: none;
}
.fundPositive {
  color: #30bb87;
}
.fundNegative {
  color: #f7445d;
}
.successContainer {
  background-color: #30bb87;
  width: auto;
  color: #ffffff;
  padding: 0.2rem;
  border-radius: 0.3rem;
}
.cancelContainer {
  background-color: #f7445d;
  width: auto;
  color: #ffffff;
  padding: 0.2rem;
  border-radius: 0.3rem;
}
.pendingContainer {
  background-color: #a6a6a6;
  width: auto;
  color: #ffffff;
  padding: 0.2rem;
  border-radius: 0.3rem;
}
</style>
