<template>
  <div class="container-price">
    <v-row no-gutters style="font-size: 0.9rem; color: #737373" class="mt-2">
      <v-col cols="4">
        <div @click="onVolClick" class="d-flex">
          <div class="my-auto">{{ $t("price.symbol/vol") }}</div>
          <div class="ml-1 my-auto d-flex flex-column">
            <img
              style="width: 0.55rem"
              :src="sortMode === 0 ? menuUpSelectedIcon : menuUpIcon"
            />
            <img
              style="width: 0.55rem"
              :src="sortMode === 1 ? menuDownSelectedIcon : menuDownIcon"
            />
          </div>
        </div>
      </v-col>
      <v-col cols="4">
        <div @click="onPriceClick" class="d-flex">
          <div class="my-auto">{{ $t("price.latestPrice") }}</div>
          <div class="ml-1 my-auto d-flex flex-column">
            <img
              style="width: 0.55rem"
              :src="sortMode === 2 ? menuUpSelectedIcon : menuUpIcon"
            />
            <img
              style="width: 0.55rem"
              :src="sortMode === 3 ? menuDownSelectedIcon : menuDownIcon"
            />
          </div>
        </div>
      </v-col>
      <v-col cols="4">
        <div @click="on24hClick" class="d-flex">
          <div class="ml-auto my-auto">{{ $t("price.24h") }}</div>
          <div class="ml-1 my-auto d-flex flex-column">
            <img
              style="width: 0.55rem"
              :src="sortMode === 4 ? menuUpSelectedIcon : menuUpIcon"
            />
            <img
              style="width: 0.55rem"
              :src="sortMode === 5 ? menuDownSelectedIcon : menuDownIcon"
            />
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row
      @click="onCoinClick(item)"
      class="my-2"
      no-gutters
      v-for="(item, index) in sortedCoinData"
      :key="index"
    >
      <v-col cols="4" class="d-flex">
        <div class="my-auto d-flex flex-column">
          <div class="d-flex">
            <div class="mt-auto text-symbol-head">{{ item.symbol_head }}</div>
            <div class="mt-auto ml-1 text-symbol-end">{{ item.symbol_end }}</div>
          </div>
          <div class="text-vol">
            {{ $t("price.vol") + (item.vol_usd_24h / 1000000).toFixed(1) + "M" }}
          </div>
        </div>
      </v-col>
      <v-col cols="4" class="d-flex">
        <div class="my-auto d-flex flex-column">
          <div
            :class="[
              'text-coin-change',
              item.change_pct_24h >= 0 ? 'text-price-up' : 'text-price-down',
            ]"
          >
            {{ item.change_24h.toPrecision(4) }}
          </div>
          <div class="text-coin-current-price">
            {{ "$" + item.current_price.toPrecision(4) }}
          </div>
        </div>
      </v-col>
      <v-col class="d-flex" cols="4">
        <div
          :class="[
            'my-auto',
            'ml-auto',
            'container-price-percentage',
            item.change_pct_24h >= 0
              ? 'container-price-percentage-up'
              : 'container-price-percentage-down',
          ]"
        >
          {{
            (item.change_pct_24h > 0 ? "+" : "") + item.change_pct_24h.toFixed(2) + "%"
          }}
        </div>
      </v-col>
    </v-row>
    <CoinDetail
      :coin="coinDetail.coin"
      v-if="coinDetail.dialog"
      v-model="coinDetail.dialog"
    />
  </div>
</template>

<script>
import CoinDetail from "./CoinDetail.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      coinDetail: { dialog: false, coin: {} },
      sortMode: 0,
      logoWhite: require("../../assets/logo/logo-1.png"),
      menuDownIcon: require("../../assets/icon/menu-down.png"),
      menuUpIcon: require("../../assets/icon/menu-up.png"),
      menuDownSelectedIcon: require("../../assets/icon/menu-down-selected.png"),
      menuUpSelectedIcon: require("../../assets/icon/menu-up-selected.png"),
    };
  },
  components: {
    CoinDetail,
  },
  computed: {
    ...mapGetters(["coinDataGetter"]),
    sortedCoinData() {
      var array = this.coinDataGetter;
      if (this.sortMode === 0) {
        array = array.sort((a, b) => {
          return a.q - b.q;
        });
      } else if (this.sortMode === 1) {
        array = array.sort((a, b) => {
          return b.q - a.q;
        });
      } else if (this.sortMode === 2) {
        array = array.sort((a, b) => {
          return a.c - b.c;
        });
      } else if (this.sortMode === 3) {
        array = array.sort((a, b) => {
          return b.c - a.c;
        });
      } else if (this.sortMode === 4) {
        array = array.sort((a, b) => {
          return a.P - b.P;
        });
      } else if (this.sortMode === 5) {
        array = array.sort((a, b) => {
          return b.P - a.P;
        });
      }
      return array;
    },
  },
  methods: {
    onCoinClick(coin) {
      this.coinDetail.coin = coin;
      this.coinDetail.dialog = true;
    },
    onVolClick() {
      if (this.sortMode == 0) {
        this.sortMode = 1;
      } else {
        this.sortMode = 0;
      }
    },
    onPriceClick() {
      if (this.sortMode === 2) {
        this.sortMode = 3;
      } else {
        this.sortMode = 2;
      }
    },
    on24hClick() {
      if (this.sortMode === 4) {
        this.sortMode = 5;
      } else {
        this.sortMode = 4;
      }
    },
  },
  created() {
  },
  mounted() {},
};
</script>

<style scoped>
.container-price {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  background: white;
}
.container-price {
  /* touch-action: pan-y !important; */
  -webkit-overflow-scrolling: touch;
}
.container-price div {
  /*   touch-action: pan-y !important; */
  -webkit-overflow-scrolling: touch;
}
.text-symbol-head {
  font-size: 1.1rem;
  color: black;
}
.text-symbol-end {
  font-size: 0.9rem;
  color: #808080;
}
.text-vol {
  font-size: 0.9rem;
  color: #808080;
}
.text-coin-change {
  font-size: 1.2rem;
}
.text-coin-current-price {
  font-size: 0.9rem;
  color: #808080;
}
.text-price-up {
  color: #30bb87;
}
.text-price-down {
  color: #f7445d;
}
.container-price-percentage {
  color: #ffffff;
  font-size: 1.1rem;
  padding: 0.5rem 1.2rem 0.5rem 1.2rem;
  text-align: center;
  border-radius: 0.5rem;
  width: 6rem;
}
.container-price-percentage-up {
  background-color: #30bb87;
}
.container-price-percentage-down {
  background-color: #f7445d;
}
</style>
