import axios from "axios";
import store from "../store";
axios.defaults.timeout = 50000;
axios.defaults.baseURL = process.env.VUE_APP_BACKENDAPI;

const onRequest = (config) => {
    config.headers = {
        Authorization: `Bearer ${store.state.auth.token}`,
        "Content-Type": (config.headers["Content-Type"] || "application/json")
    }
    return config;
}
const onRequestError = (error) => {
    return Promise.reject(error);
}
const onResponse = (response) => {
    try {
        let [scheme, token] = response.header.authorization.split(" ");
        if (scheme == "Bearer") {
            store.commit("tokenSetter", token);
        }
        return response;
    } catch (e) { return response }
}
const onResponseError = (error) => {

    console.log(error.response.status)
    if (error.response.status == 401) {
        store.commit("homeIndexSetter", 0);
        store.commit("authSetter", {
            isLoggedIn: false,
            token: "",
        });
    }

    return Promise.reject(error);
}

axios.interceptors.request.use(onRequest, onRequestError);
axios.interceptors.response.use(onResponse, onResponseError);
export default axios;
export { axios }