import Vue from 'vue'
import VueRouter from 'vue-router'
import MainPage from '../pages/mainPage/index.vue'
import Login from '../pages/login/index.vue'
import Referral from "../pages/others/referrals.vue"
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'main',
    component: MainPage
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: "/referral",
    name: "referral",
    component: Referral
  }
]

const router = new VueRouter({
  mode: "history",
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    next("/")
  } else {
    next()
  }
});
export default router
