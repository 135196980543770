<template>
  <div class="container-home">
    <div class="container-inner pt-1 pb-4" style="background-color: #242640">
      <v-carousel
        class="carousel-border"
        cycle
        height="140"
        hide-delimiter-background
        show-arrows-on-hover
      >
        <v-carousel-item
          class="carousel-border"
          v-for="(item, i) in carousel"
          :key="i"
          :src="item"
        >
        </v-carousel-item>
      </v-carousel>
    </div>
    <div style="position: relative; background-color: #f1f0f0">
      <div
        class="w-full"
        style="position: absolute; top: 0px; background-color: #242640; height: 50px"
      ></div>
      <div class="market-card-relative">
        <div class="text-market-title mb-2">{{ $t("home.market") }}</div>
        <v-row no-gutters>
          <v-col cols="4">
            <div class="text-market-coin-title">BTC/USDT</div>
            <div class="d-flex mt-1">
              <img
                class="my-auto"
                style="width: 30px; object-fit: contain"
                :src="require('../../assets/crypto-icon/btc.png')"
              />
              <div class="ml-2 d-flex flex-column">
                <div
                  :class="[
                    'text-market-coin',
                    coinBTCGetter.change_pct_24h > 0
                      ? 'text-price-up'
                      : 'text-price-down',
                  ]"
                >
                  {{ coinBTCGetter.change_pct_24h > 0 ? "+" : ""
                  }}{{ coinBTCGetter.change_pct_24h.toFixed(2) + "%" }}
                </div>
                <div
                  :class="[
                    'text-market-value',
                    'overflow-hidden',
                    coinBTCGetter.change_pct_24h > 0
                      ? 'text-price-up'
                      : 'text-price-down',
                  ]"
                >
                  {{ coinBTCGetter.current_price.toFixed(2) }}
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="4">
            <div class="text-market-coin-title">ETH/USDT</div>
            <div class="d-flex mt-1">
              <img
                class="my-auto"
                style="width: 30px; object-fit: contain"
                :src="require('../../assets/crypto-icon/eth.png')"
              />
              <div class="ml-2 d-flex flex-column">
                <div
                  :class="[
                    'text-market-coin',

                    coinETHGetter.change_pct_24h > 0
                      ? 'text-price-up'
                      : 'text-price-down',
                  ]"
                >
                  {{ coinETHGetter.change_pct_24h > 0 ? "+" : ""
                  }}{{ coinETHGetter.change_pct_24h.toFixed(2) + "%" }}
                </div>
                <div
                  :class="[
                    'text-market-value',
                    'overflow-hidden',
                    coinETHGetter.change_pct_24h > 0
                      ? 'text-price-up'
                      : 'text-price-down',
                  ]"
                >
                  {{ coinETHGetter.current_price.toFixed(2) }}
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="4">
            <div class="text-market-coin-title">BNB/USDT</div>
            <div class="d-flex mt-1">
              <img
                class="my-auto"
                style="width: 30px; object-fit: contain"
                :src="require('../../assets/crypto-icon/bnb.png')"
              />
              <div class="ml-2 d-flex flex-column">
                <div
                  :class="[
                    'text-market-coin',
                    coinBNBGetter.change_pct_24h > 0
                      ? 'text-price-up'
                      : 'text-price-down',
                  ]"
                >
                  {{ coinBNBGetter.change_pct_24h > 0 ? "+" : ""
                  }}{{ coinBNBGetter.change_pct_24h.toFixed(2) + "%" }}
                </div>
                <div
                  :class="[
                    'text-market-value',
                    'overflow-hidden',
                    coinBNBGetter.change_pct_24h > 0
                      ? 'text-price-up'
                      : 'text-price-down',
                  ]"
                >
                  {{ coinBNBGetter.current_price.toFixed(2) }}
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="container-inner py-4" style="background-color: #f1f1f1">
      <v-row
        v-if="authGetter.isLoggedIn"
        no-gutters
        class="mx-auto"
        style="width: 99%; margin-top: 10px; margin-bottom: 18px"
      >
        <v-col v-for="(item, index) in buttonList" cols="3" :key="index">
          <div @click="item.onClick" class="d-flex flex-column my-auto">
            <img class="mx-auto" style="height: 30px" :src="item.icon" />
            <div class="mx-auto mt-1" style="font-size: 13px; color: #4d4d4d">
              {{ $t(`home.${item.key}`) }}
            </div>
          </div>
        </v-col>
      </v-row>

      <div
        v-if="authGetter.isLoggedIn"
        @click="onDepositClick"
        class="deposit-card d-flex"
      >
        <div class="ml-4 my-auto d-flex flex-column">
          <div class="deposit-text-title">{{ $t("home.depositBox.name") }}</div>
          <div class="deposit-text-subtitle">{{ $t("home.depositBox.content") }}</div>
        </div>
        <v-img contain max-width="70" class="ml-auto" :src="depositIcon" />
      </div>
      <div v-else @click="onLoginClick" class="deposit-card d-flex">
        <div class="ml-4 my-auto d-flex flex-column">
          <div class="deposit-text-title">Login</div>
          <div class="deposit-text-subtitle">{{ $t("home.depositBox.content") }}</div>
        </div>
        <v-img
          contain
          max-width="70"
          class="ml-auto"
          :src="require('../../assets/login.png')"
        />
      </div>
      <div class="ranking-card mt-4">
        <div class="ranking-text-title">{{ $t("home.ranking") }}</div>
        <div class="d-flex flex-column">
          <div class="d-flex mt-1">
            <div
              @click="rankingTabIndex = 0"
              :class="[
                'mx-1',
                'ranking-tab',
                { 'ranking-tab-selected': rankingTabIndex === 0 },
              ]"
            >
              {{ $t("home.gainer") }}
            </div>
            <div
              @click="rankingTabIndex = 1"
              :class="[
                'mx-1',
                'ranking-tab',
                { 'ranking-tab-selected': rankingTabIndex === 1 },
              ]"
            >
              {{ $t("home.loser") }}
            </div>
          </div>

          <v-row no-gutters style="font-size: 0.9rem; color: #737373" class="mt-2">
            <v-col cols="4">{{ $t("home.symbol/vol") }}</v-col>
            <v-col cols="4">{{ $t("home.latestPrice") }}</v-col>
            <v-col align="end" cols="4">{{ $t("home.24h") }}</v-col>
          </v-row>
          <v-row
            class="my-1"
            no-gutters
            v-for="(item, index) in rankingTabIndex === 0
              ? coinDataGainerGetter
              : coinDataLoserGetter"
            :key="index"
          >
            <v-col cols="4" class="d-flex">
              <div class="my-auto d-flex flex-column">
                <div class="d-flex">
                  <div class="mt-auto text-symbol-head">{{ item.symbol_head }}</div>
                  <div class="mt-auto ml-1 text-symbol-end">{{ item.symbol_end }}</div>
                </div>
                <div class="text-vol">
                  {{ "Vol " + (item.vol_usd_24h / 1000000).toFixed(1) + "M" }}
                </div>
              </div>
            </v-col>
            <v-col cols="4" class="d-flex">
              <div class="my-auto d-flex flex-column">
                <div
                  :class="[
                    'text-coin-change',
                    item.change_pct_24h >= 0 ? 'text-price-up' : 'text-price-down',
                  ]"
                >
                  {{ item.change_24h.toPrecision(4)  }}
                </div>
                <div class="text-coin-current-price">
                  {{ "$" + item.current_price.toPrecision(4)  }}
                </div>
              </div>
            </v-col>
            <v-col class="d-flex" cols="4">
              <div
                :class="[
                  'my-auto',
                  'ml-auto',
                  'container-price-percentage',
                  item.change_pct_24h >= 0
                    ? 'container-price-percentage-up'
                    : 'container-price-percentage-down',
                ]"
              >
                {{
                  (item.change_pct_24h > 0 ? "+" : "") +
                  item.change_pct_24h.toFixed(2) +
                  "%"
                }}
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <Deposit v-model="dialog.deposit" v-if="dialog.deposit" />
    <FundDetail v-model="dialog.fundDetail" v-if="dialog.fundDetail" />
    <Staking v-model="dialog.staking" v-if="dialog.staking" />
    <Referral
      :referralCode="userDataGetter.referralCode"
      v-model="dialog.referral"
      v-if="dialog.referral"
    />
  </div>
</template>

<script>
import Deposit from "../deposit/index.vue";
import FundDetail from "./FundDetail.vue";
import Referral from "./Referral.vue";
import Staking from "../staking/index.vue";
import { mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      carousel: [
        require("../../assets/carousel/bg1.png"),
        /*  require("../../assets/carousel/carousel_nft.jpeg"),
        require("../../assets/carousel/carousel_cryptocurrency.jpeg"), */
      ],
      depositIcon: require("../../assets/home-deposit.png"),
      leftArrowIcon: require("../../assets/icon/left-arrow.png"),
      buttonList: [
        {
          key: "staking",
          icon: require("../../assets/staking.png"),
          onClick: () => {
            this.dialog.staking = true;
          },
        },
        {
          key: "fundDetails",
          icon: require("../../assets/fund.png"),
          onClick: () => {
            this.dialog.fundDetail = true;
          },
        },
        {
          key: "support",
          icon: require("../../assets/support.png"),
          onClick: () => {
            window.location.href = "mailto: cs@chainbitvortex.com";
          },
        },
        {
          key: "referral",
          icon: require("../../assets/referral.png"),
          onClick: () => {
            this.dialog.referral = true;
          },
        },
      ],
      rankingTabIndex: 0,
      dialog: {
        deposit: false,
        fundDetail: false,
        staking: false,
        referral: false,
      },
    };
  },
  components: { Deposit, FundDetail, Staking, Referral },
  computed: {
    ...mapGetters([
      "coinDataGainerGetter",
      "coinDataLoserGetter",
      "coinBTCGetter",
      "coinETHGetter",
      "coinBNBGetter",
      "userDataGetter",
      "authGetter",
    ]),
    getCoinsDetails(symbol) {
      let found = this.coinDataGetter.find((element) => element.coin_symbol == symbol);
      if (typeof found === "undefined") {
        return { change_pct_24h: 0, current_price: 0 };
      } else {
        return found;
      }
    },
  },
  methods: {
    ...mapMutations(["loginDialogShowSetter"]),
    onLoginClick() {
      this.loginDialogShowSetter(true);
    },
    onDepositClick() {
      if (!this.authGetter.isLoggedIn) {
        this.loginDialogShowSetter(true);
        return;
      }

      this.dialog.deposit = true;
    },
  },
  created() {},
  mounted() {
    console.log(this.coinBNBGetter);
  },
};
</script>
<style scoped>
.container-inner {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.container-home {
  touch-action: pan-y !important;
}
.container-home div {
  touch-action: pan-y !important;
}
.carousel-border {
  border-radius: 0.5rem;
}
.text-market-title {
  font-size: 1.4rem;
  color: black;
}
.text-market-coin-title {
  font-size: 16px;
  color: #8c8c8c;
}
.text-market-coin {
  font-size: 14px;
  color: #8c8c8c;
}
.text-market-value {
  font-size: 17px;
  color: #8c8c8c;
}
.deposit-card {
  background: white;
  padding: 1.4rem 0.4rem;
  border-radius: 0.5rem;
  background-image: -webkit-linear-gradient(62deg, #418cd6 0%, #c1a9d6 100%);
  background-image: -moz-linear-gradient(62deg, #418cd6 0%, #c1a9d6 100%);
  background-image: -o-linear-gradient(62deg, #418cd6 0%, #c1a9d6 100%);
  background-image: linear-gradient(62deg, #418cd6 0%, #c1a9d6 100%);
}
.market-card-relative {
  background: white;
  padding: 0.9rem;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  border-radius: 0.5rem;
  position: relative;
  top: 0rem;
}
.deposit-text-title {
  color: #fff;
  font-weight: bold;
  font-size: 1.7rem;
}
.deposit-text-subtitle {
  color: #fff;
  font-weight: bold;
  font-size: 1.1rem;
}
.ranking-card {
  background: white;
  padding: 0.9rem;
  border-radius: 0.5rem;
}
.ranking-text-title {
  font-size: 1.4rem;
  color: black;
}

.ranking-tab-selected {
  background-color: #d9d9d9;
  color: #4d4d4d !important;
}
.ranking-tab {
  margin-right: 20;
  padding: 0.3rem 0.6rem 0.3rem 0.6rem;
  width: auto;
  color: #b3b3b3;
  font-size: 1rem;
  border-radius: 0.5rem;
}
.text-symbol-head {
  font-size: 1.1rem;
  color: black;
}
.text-symbol-end {
  font-size: 0.9rem;
  color: #808080;
}
.text-vol {
  font-size: 0.9rem;
  color: #808080;
}
.text-coin-change {
  font-size: 1.2rem;
}
.text-coin-current-price {
  font-size: 0.9rem;
  color: #808080;
}
.text-price-up {
  color: #30bb87;
}
.text-price-down {
  color: #f7445d;
}
.container-price-percentage {
  color: #ffffff;
  font-size: 1.1rem;
  padding: 0.5rem 1.2rem 0.5rem 1.2rem;
  text-align: center;
  border-radius: 0.5rem;
}
.container-price-percentage-up {
  background-color: #30bb87;
}
.container-price-percentage-down {
  background-color: #f7445d;
}
</style>
