<template>
  <div class="pb-8 footer-container">
    <v-row no-gutters class="mt-2">
      <v-col v-for="(item, index) in menu" :key="index">
        <div @click="onItemClick(index)" style="d-flex flex-column">
          <img
            class="mx-auto icon-tab"
            :src="homeIndexGetter === index ? item.selectedIcon : item.icon"
          />
          <div
            :class="[
              'mx-auto',
              'text-tab',
              'text-center',
              { 'text-tab-selected': homeIndexGetter == index },
            ]"
          >
            {{ $t(`main.menu.${item.value}`) }}
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      menu: [
        {
          value: "home",
          icon: require("../../assets/icon/home-outline.png"),
          selectedIcon: require("../../assets/icon/home-filled.png"),
        },
        {
          value: "price",
          icon: require("../../assets/icon/chart-line.png"),
          selectedIcon: require("../../assets/icon/chart-line-filled.png"),
        },
        {
          value: "trade",
          icon: require("../../assets/icon/cash-usd-outline.png"),
          selectedIcon: require("../../assets/icon/cash-usd-filled.png"),
        },
        {
          value: "account",
          icon: require("../../assets/icon/account-outline.png"),
          selectedIcon: require("../../assets/icon/account-filled.png"),
        },
      ],
    };
  },
  computed: { ...mapGetters(["homeIndexGetter", "authGetter"]) },
  methods: {
    ...mapMutations(["homeIndexSetter","loginDialogShowSetter"]),
    onItemClick(i) {
      if (this.authGetter.isLoggedIn) {
        this.homeIndexSetter(i);
      } else {
        if (i == 2 || i == 3) {
          this.loginDialogShowSetter(true)
          return;
        }
        this.homeIndexSetter(i);
      }
    },
  },
};
</script>

<style scoped>
.footer-container {
  border-top-width: 1px;
  border-top-color: #b3b3b3;
  border-top-style: solid;
  background-color: white;
}
.text-tab {
  color: #737373;
  font-size: 0.75rem;
  font-weight: 400;
}
.text-tab-selected {
  color: #1e2036;
}
.icon-tab {
  width: 2rem;
}
</style>
