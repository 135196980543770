<template>
  <v-dialog
    fullscreen
    persistent
    style="background-color: white"
    @input="$emit('input')"
    :value="value"
  >
    <v-card>
      <v-toolbar style="position: sticky; top: 0; z-index: 100" dark color="#3d406c">
        <v-btn icon @click="$emit('input')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("staking.stakingHistory.title") }}</v-toolbar-title>
      </v-toolbar>
      <div class="d-flex flex-column px-2">
        <v-tabs v-model="tab" color="#636689" fixed-tabs class="mx-auto">
          <v-tab> {{ $t("staking.stakingHistory.stake") }}</v-tab>
          <v-tab>{{ $t("staking.stakingHistory.redempted") }}</v-tab>
          <v-tab>{{ $t("staking.stakingHistory.interest") }}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item
            ><div
              class="d-flex flex-column p-3"
              v-for="(item, index) in activeOrder"
              :key="index"
              style="border-bottom: 1px solid #e6e6e6"
              @click="onStakingItemClick(item)"
            >
              <div class="d-flex">
                <img
                  style="height: 1.4rem"
                  class="my-auto"
                  :src="
                    item.coin_symbol == 'USDT'
                      ? coinInfo[item.coin_symbol].img
                      : coinInfo[item.coin_symbol + 'USDT'].img
                  "
                />
                <div style="font-size: 1.1rem" class="my-auto ml-2">
                  {{ item.coin_symbol }}
                </div>
                <div style="font-size: 0.8rem" class="my-auto ml-auto">
                  {{ moment(item).format("YYYY-MM-DD hh:mm:ss") }}
                </div>
              </div>
              <div style="font-size: 1rem" class="mt-2">
                {{ `${$t("staking.stakingHistory.period")}: ${item.period} Days` }}
              </div>
              <div style="font-size: 1rem">
                {{
                  `${$t("staking.stakingHistory.amount")} (${item.coin_symbol}): ${
                    item.amount
                  }`
                }}
              </div>
              <div style="font-size: 1rem">
                {{
                  `${$t("staking.stakingHistory.cumulativeInterest")}: ${item.profit} `
                }}
              </div>
            </div></v-tab-item
          >
          <v-tab-item
            ><div
              class="d-flex flex-column p-3"
              v-for="(item, index) in redemptedOrder"
              :key="index"
              style="border-bottom: 1px solid #e6e6e6"
              @click="onStakingItemClick(item)"
            >
              <div class="d-flex">
                <img
                  style="height: 1.5rem"
                  class="my-auto"
                  :src="
                    item.coin_symbol == 'USDT'
                      ? coinInfo[item.coin_symbol].img
                      : coinInfo[item.coin_symbol + 'USDT'].img
                  "
                />
                <div style="font-size: 1.1rem" class="my-auto ml-2">
                  {{ item.coin_symbol }}
                </div>
                <div style="font-size: 0.8rem" class="my-auto ml-auto">
                  {{ moment(item.value_date).format("YYYY-MM-DD hh:mm:ss") }}
                </div>
              </div>

              <div style="font-size: 1rem" class="mt-2">
                {{
                  `${$t("staking.stakingHistory.amount")} (${item.coin_symbol}): ${
                    item.amount
                  }`
                }}
              </div>
              <div style="font-size: 1rem">
                {{
                  `${$t("staking.stakingHistory.cumulativeInterest")}: ${item.profit} `
                }}
              </div>
              <div style="font-size: 1rem">
                {{
                  `${$t("staking.stakingHistory.redemptionDate")}: ${moment(
                    item.redemption_date
                  ).format("YYYY-MM-DD hh:mm:ss")} `
                }}
              </div>
            </div></v-tab-item
          >
          <v-tab-item
            ><div
              class="d-flex p-3"
              v-for="(item, index) in stakingInterest"
              :key="index"
              style="border-bottom: 1px solid #e6e6e6"
            >
              <div class="d-flex flex-column">
                <div class="d-flex">
                  <img
                    style="height: 1.5rem"
                    class="my-auto"
                    :src="
                      item.coin_symbol == 'USDT'
                        ? coinInfo[item.coin_symbol].img
                        : coinInfo[item.coin_symbol + 'USDT'].img
                    "
                  />
                  <div style="font-size: 1.3rem" class="my-auto ml-2">
                    {{ item.coin_symbol }}
                  </div>
                </div>
                <div class="mt-2">{{ $t("staking.stakingHistory.interestAmount") }}</div>
              </div>
              <div class="my-auto ml-auto d-flex flex-column">
                <div style="font-size: 0.8rem">
                  {{ moment(item.created_at).format("YYYY-MM-DD hh:mm:ss") }}
                </div>
                <div class="text-right mt-2">{{ item.profit.toFixed(4) }}</div>
              </div>
            </div></v-tab-item
          >
        </v-tabs-items>
      </div>
    </v-card>
    <v-overlay :value="isLoading">
      <v-progress-circular
        color="blue darken-4"
        indeterminate
        size="40"
      ></v-progress-circular>
    </v-overlay>
    <StakingDetail
      v-if="dialog.stakingDetail"
      v-model="dialog.stakingDetail"
      :staking="selectedStaking"
    />
  </v-dialog>
</template>

<script>
import coinInfo from "../../utils/coinInfo";
import StakingDetail from "../account/StakingDetail.vue";
import axios from "../../axios";
import moment from "moment";
import { mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      isLoading: true,
      tab: 0,
      dialog: { stakingDetail: false },
      activeOrder: [],
      redemptedOrder: [],
      stakingInterest: [],
      selectedStaking: {},
    };
  },
  props: ["value"],
  components: { StakingDetail },
  computed: {
    coinInfo() {
      return coinInfo;
    },
    moment() {
      return moment;
    },
    ...mapGetters(["walletAddressGetter", "cryptoIconGetter"]),
  },
  methods: {
    ...mapMutations(["snackbarSetter"]),
    onStakingItemClick(item) {
      this.selectedStaking = item;

      this.dialog.stakingDetail = true;
    },
    fetchAllOrder() {
      axios.get("/user/getUserStaking").then((response) => {
        console.log(response);
        this.isLoading = false;
        let activeOrder = response.data.staking_cureent;
        let redemptedOrder = response.data.staking_redeemed;
        let stakingInterest = response.data.profit;

        activeOrder = activeOrder.reverse();
        redemptedOrder = redemptedOrder.reverse();
        stakingInterest = stakingInterest.reverse();

        this.activeOrder = activeOrder;
        this.redemptedOrder = redemptedOrder;
        this.stakingInterest = stakingInterest;
      });
    },
  },
  created() {
    this.fetchAllOrder();
  },
  mounted() {},
};
</script>

<style scoped></style>
