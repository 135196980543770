<template>
  <div class="d-flex flex-column" style="overflow: hidden; height: 100%">
    <Header class="mb-auto sticky-footer" />

    <div class="flex-grow-1 mid-container">
      <Home v-if="homeIndexGetter === 0" />
      <Price v-if="homeIndexGetter === 1" />
      <Trade v-if="homeIndexGetter === 2" />
      <Account v-if="homeIndexGetter === 3" />
    </div>
    <LoginDialog v-model="loginDialogVisible" />
    <Footer class="mt-auto sticky-footer" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import Home from "../home/index.vue";
import LoginDialog from "../../components/loginDialog.vue";
import Price from "../price/index.vue";
import Trade from "../trade/index.vue";
import Account from "../account/index.vue";
export default {
  data() {
    return {
      fetchCoinDataWorker: "",
      fetchUserDataWorker: "",
      fetchStakingDataWorker: "",
    };
  },
  components: {
    Header,
    Footer,
    Home,
    Price,
    Trade,
    Account,
    LoginDialog,
  },
  computed: {
    ...mapGetters(["loginDialogShowGetter", "coinDataGainerGetter", "homeIndexGetter"]),
    loginDialogVisible: {
      get() {
        return this.loginDialogShowGetter;
      },
      set() {
        return this.loginDialogShowSetter(false);
      },
    },
  },
  methods: {
    ...mapMutations(["loginDialogShowSetter"]),
    ...mapActions(["fetchCoinData", "fetchUserData", "fetchStakingData"]),
  },
  created() {
    this.fetchCoinData();
    this.fetchCoinDataWorker = setInterval(this.fetchCoinData, 2000);
    this.fetchUserData();
    this.fetchUserDataWorker = setInterval(this.fetchUserData, 2000);
    /*     this.fetchUserData();
    this.fetchUserDataWorker = setInterval(this.fetchUserData, 2000);
    this.fetchStakingData();
    this.fetchStakingDataWorker = setInterval(this.fetchStakingData, 2000); */
  },

  beforeDestroy() {
    clearInterval(this.fetchCoinDataWorker);
    clearInterval(this.fetchUserDataWorker);
    /*   clearInterval(this.fetchUserDataWorker);
    clearInterval(this.fetchStakingDataWorker); */
  },
  mounted() {},
};
</script>
<style scoped>
.absolute-header {
  position: sticky;
  top: 0;
}
.mid-container {
  position: relative;
}

.mid-container > div {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: scroll;
  overflow-x:hidden;
}
.absolute-footer {
  position: sticky;
  bottom: 0;
}
</style>
