<template>
  <v-dialog
    fullscreen
    persistent
    style="background-color: white"
    @input="$emit('input')"
    :value="value"
  >
    <v-card style="height: 100%">
      <div
        style="background-color: #f5f5f5; height: 100%"
        class="container-coin d-flex flex-column"
      >
        <v-row no-gutters class="flex-shrink-1 px-4">
          <v-col cols="12">
            <div class="d-flex my-2">
              <v-btn icon @click="$emit('input')">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <div class="my-auto ml-4" style="color: #030303; font-size: 20px">
                {{ coin.symbol_head + coin.symbol_end }}
              </div>
            </div>
          </v-col>
        </v-row>
        <div class="flex-grow-0 px-4" style="position: relative; overflow-y: scroll">
          <v-row no-gutters class="mx-2 mt-1">
            <v-col cols="6" class="pr-1">
              <div class="d-flex flex-column">
                <div class="d-flex my-auto">
                  <div
                    style="white-space: nowrap; overflow: hidden"
                    class="coin-price-text my-auto"
                  >
                    {{ "$" }}
                    {{
                      coin.is_customized
                        ? coinInfo.current_price.toPrecision(4)
                        : coinInfo.current_price
                    }}
                  </div>
                  <img
                    style="width: 1.3rem"
                    class="flex-shrink-1 my-auto"
                    :src="coinInfo.change_pct_24h < 0 ? arrowDownIcon : arrowUpIcon"
                  />
                </div>
                <div
                  :class="[
                    'd-flex',
                    'mt-1',
                    'coin-change-text',
                    coinInfo.change_pct_24h < 0 ? 'text-price-down' : 'text-price-up',
                  ]"
                >
                  <div>
                    {{
                      coin.is_customized
                        ? coinInfo.change_24h.toPrecision(4)
                        : coinInfo.change_24h
                    }}
                  </div>
                  <div class="ml-2">
                    {{
                      coin.is_customized
                        ? coinInfo.change_pct_24h.toPrecision(4)
                        : coinInfo.change_pct_24h
                    }}{{ "%" }}
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="6" class="px-4">
              <v-row no-gutters>
                <v-col cols="6">
                  <div class="d-flex flex-column">
                    <div class="text-coin-subtitle">
                      {{ $t("price.coinDetail.24hHigh") }}
                    </div>
                    <div class="text-coin-subtitle-value">
                      {{ coinInfo.highest_24h.toPrecision(4) }}
                    </div>
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="d-flex flex-column">
                    <div class="text-coin-subtitle">
                      {{ $t("price.coinDetail.24hVol") }}
                    </div>
                    <div class="text-coin-subtitle-value">
                      {{ coinInfo.vol_24h.toPrecision(4) }}
                    </div>
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="d-flex flex-column mt-1">
                    <div class="text-coin-subtitle">
                      {{ $t("price.coinDetail.24hLow") }}
                    </div>
                    <div class="text-coin-subtitle-value">
                      {{ coinInfo.lowest_24h.toPrecision(4) }}
                    </div>
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="d-flex flex-column mt-1">
                    <div class="text-coin-subtitle">
                      {{ $t("price.coinDetail.24hVolUSDT") }}
                    </div>
                    <div class="text-coin-subtitle-value">
                      {{ (coinInfo.vol_usd_24h / 1000000).toFixed(1) + "M" }}
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <div ref="chartContainer" class="mt-4">
            <v-row no-gutters class="chart-tab-container">
              <v-col class="d-flex" cols="2" @click="changeTab('1m')">
                <div
                  :class="[
                    'mx-auto',
                    'my-auto',
                    { 'chart-selected-text ': chartTab == '1m' },
                  ]"
                >
                  {{ $t("price.coinDetail.1m") }}
                </div>
              </v-col>
              <v-col class="d-flex" cols="2" @click="changeTab('15m')">
                <div
                  :class="[
                    'mx-auto',
                    'my-auto',
                    { 'chart-selected-text ': chartTab == '15m' },
                  ]"
                >
                  {{ $t("price.coinDetail.15m") }}
                </div>
              </v-col>
              <v-col class="d-flex" cols="2" @click="changeTab('1h')">
                <div
                  :class="[
                    'mx-auto',
                    'my-auto',
                    { 'chart-selected-text ': chartTab == '1h' },
                  ]"
                >
                  {{ $t("price.coinDetail.1h") }}
                </div>
              </v-col>
              <v-col class="d-flex" cols="2" @click="changeTab('4h')">
                <div
                  :class="[
                    'mx-auto',
                    'my-auto',
                    { 'chart-selected-text ': chartTab === '4h' },
                  ]"
                >
                  {{ $t("price.coinDetail.4h") }}
                </div>
              </v-col>
              <v-col class="d-flex" cols="2" @click="changeTab('1d')">
                <div
                  :class="[
                    'mx-auto',
                    'my-auto',
                    { 'chart-selected-text ': chartTab === '1d' },
                  ]"
                >
                  {{ $t("price.coinDetail.1d") }}
                </div>
              </v-col>
            </v-row>
          </div>

          <div
            class="noselect"
            style="background-color: #121826; position: relative; height: 350px"
          >
            <v-overlay v-if="isChartLoading" absolute>
              <v-progress-circular indeterminate></v-progress-circular>
            </v-overlay>

            <TradingVue
              v-else
              :timezone="timezoneOffset"
              :titleTxt="coin.symbol_head + coin.symbol_end"
              :height="350"
              :width="chartWidth"
              :data="chartData"
            ></TradingVue>
          </div>
          <div
            class="noselect"
            style="background-color: #121826; height: 10px; border-radius: 0 0 10px 10px"
          ></div>
          <div class="mt-5 d-flex flex-column" style="">
            <div class="history-title-container">
              {{ $t("price.coinDetail.order") }}
            </div>
            <div style="color: #737373" class="d-flex mt-1">
              <div style="width: 50%">{{ $t("price.coinDetail.buy") }}</div>
              <div style="width: 50%">{{ $t("price.coinDetail.sell") }}</div>
            </div>
            <div class="d-flex" style="font-weight: bold">
              <div style="color: #30bb87">
                {{ ((buySell[3] * 100) / (buySell[3] * 1 + buySell[2] * 1)).toFixed(1)
                }}{{ "%" }}
              </div>
              <div class="d-flex flex-grow-1 mx-2">
                <div
                  :style="{
                    width:
                      Math.floor((buySell[3] * 100) / (buySell[3] * 1 + buySell[2] * 1)) +
                      '%',
                  }"
                  style="background-color: #30bb87; height: 4px; transition: width 0.3s"
                  class="my-auto"
                ></div>
                <div
                  :style="{
                    width:
                      Math.floor(
                        ((buySell[2] * 100) / (buySell[3] * 1 + buySell[2] * 1)) * 1
                      ) + '%',
                  }"
                  style="background-color: #f7445d; height: 4px; transition: width 0.3s"
                  class="my-auto"
                ></div>
              </div>
              <div style="color: #f7445d">
                {{ ((buySell[2] * 100) / (buySell[3] * 1 + buySell[2] * 1)).toFixed(1)
                }}{{ "%" }}
              </div>
            </div>
            <div style="color: #737373" class="d-flex mt-1">
              <div
                style="background-color: #069b40; width: 50%; color: #ffffff"
                class="pa-2 d-flex"
              >
                <div>
                  {{ coin.symbol_head }}
                </div>
                <div class="ml-2">${{ buySell[1] }}</div>
                <div class="ml-auto">
                  {{ buySell[3] }}
                </div>
              </div>

              <div
                style="background-color: #db2020; width: 50%; color: #ffffff"
                class="pa-2 d-flex"
              >
                <div>
                  {{ coin.symbol_head }}
                </div>
                <div class="ml-2">${{ buySell[0] }}</div>
                <div class="ml-auto">
                  {{ buySell[2] }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-shrink-1 px-4" style="background-color: #e0e0e0">
          <button @click="tradeButton" class="w-full button-login mt-4 mb-8">
            {{ $t("price.coinDetail.trade") }}
          </button>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import TradingVue from "trading-vue-js";
import { mapMutations, mapGetters } from "vuex";
import axios from "axios";
export default {
  data() {
    return {
      arrowUpIcon: require("../../assets/icon/arrow-up.png"),
      arrowDownIcon: require("../../assets/icon/arrow-down.png"),
      noRecordIcon: require("../../assets/icon/record.png"),
      chartTab: "1h",
      chartWidth: 100,
      chartHeight: 100,
      isChartLoading: true,
      timezoneOffset: 0,
      chartData: {
        ohlcv: [],
      },
      chart: [],
      buySell: [],
      intervalWorker: 0,
    };
  },
  props: ["value", "coin"],
  components: { TradingVue },
  computed: {
    ...mapGetters(["coinDataGetter", "authGetter"]),
    coinInfo() {
      let coin = this.coinDataGetter.find((element) => {
        return element.coin_symbol == this.coin.coin_symbol;
      });

      return coin;
    },
  },
  methods: {
    ...mapMutations(["tradeSymbolSetter", "homeIndexSetter", "loginDialogShowSetter"]),
    tradeButton() {
      if (this.authGetter.isLoggedIn) {
        this.tradeSymbolSetter(this.coin.coin_symbol);
        this.homeIndexSetter(2);
      } else {
        this.loginDialogShowSetter(true);
      }
    },
    async changeTab(interval) {
      this.chartTab = interval;
      this.isChartLoading = true;
      await this.getChart(interval);
      this.isChartLoading = false;
    },
    async getChart(interval) {
      let res = await axios
        .create({
          baseURL: process.env.VUE_APP_BACKENDAPI,
        })
        .post(`/coin/getCoinChart`, {
          isCustomized: this.coin.is_customized,
          coinSymbol: this.coin.coin_symbol,
          interval: interval,
        });

      let data = res.data.chart;

      let ohlcv = data.map((d) => {
        return [
          d[0],
          parseFloat(d[1]),
          parseFloat(d[2]),
          parseFloat(d[3]),
          parseFloat(d[4]),
          parseFloat(d[5]),
        ];
      });

      let ma7data = ohlcv.map((d, index, array) => {
        let ma7 = 0;
        let count = 0;
        for (let i = index; i > index - 7; i--) {
          try {
            ma7 = ma7 + parseFloat(array[i][4]);
            count = count + 1;
          } catch (e) {
            e;
          }
        }
        ma7 = ma7 / count;
        return [d[0], ma7];
      });

      let ma25data = ohlcv.map((d, index, array) => {
        let ma25 = 0;
        let count = 0;
        for (let i = index; i > index - 25; i--) {
          try {
            ma25 = ma25 + parseFloat(array[i][4]);
            count = count + 1;
          } catch (e) {
            e;
          }
        }
        ma25 = ma25 / count;
        return [d[0], ma25];
      });

      let ma99data = ohlcv.map((d, index, array) => {
        let ma99 = 0;
        let count = 0;
        for (let i = index; i > index - 99; i--) {
          try {
            ma99 = ma99 + parseFloat(array[i][4]);
            count = count + 1;
          } catch (e) {
            e;
          }
        }
        ma99 = ma99 / count;
        return [d[0], ma99];
      });
      let numberToSlice = 0;
      if (data.length < 200) {
        numberToSlice = 0;
      } else {
        numberToSlice = 99 - (299 - data.length);
      }

      this.chartData = {
        ohlcv: ohlcv.slice(numberToSlice),
        onchart: [
          {
            name: "MA(7)",
            type: "EMA",
            data: ma7data.slice(numberToSlice),
            settings: { color: "#ede06d" },
          },
          {
            name: "MA(25)",
            type: "EMA",
            data: ma25data.slice(numberToSlice),
            settings: { color: "#fc77e2" },
          },
          {
            name: "MA(99)",
            type: "EMA",
            data: ma99data.slice(numberToSlice),
            settings: { color: "#b030ff" },
          },
        ],
      };

      /*
      this.$nextTick(() => {
        this.$refs.chart1d.setRange(data[0][0], data[data.length - 1][0]);
      }); */

      return;
    },
    onWindowResize() {
      let chartContainer = this.$refs.chartContainer;
      this.chartWidth = chartContainer.clientWidth;
      this.chartHeight = 300;
    },
    buySellSetup() {
      let sellSide = this.coinInfo.current_price * (1 + Math.random() * 0.002);
      let buySide = this.coinInfo.current_price * (1 - Math.random() * 0.002);
      let sellSideVol = (5000 * (0.5 + Math.random())) / this.coinInfo.current_price;
      let buySideVol = (5000 * (0.5 + Math.random())) / this.coinInfo.current_price;
      if (sellSideVol >= 1) {
        sellSideVol = Math.floor(sellSideVol);
      } else {
        {
          sellSideVol = sellSideVol.toPrecision(3);
        }
      }
      if (buySideVol >= 1) {
        buySideVol = Math.floor(buySideVol);
      } else {
        {
          buySideVol = buySideVol.toPrecision(3);
        }
      }
      if (this.coinInfo.current_price < 1) {
        sellSide = sellSide.toPrecision(4);
        buySide = buySide.toPrecision(4);
      } else {
        sellSide = sellSide.toFixed(2);
        buySide = buySide.toFixed(2);
      }
      console.log(sellSide);
      this.buySell = [sellSide, buySide, sellSideVol, buySideVol];
      console.log(this.buySell);
    },
  },

  async mounted() {
    this.buySellSetup();
    let a = async () => {
      await this.getChart("1m");
      this.isChartLoading = false;
      window.addEventListener("resize", this.onWindowResize);
      this.$nextTick(() => {
        this.onWindowResize();
      });
      this.timezoneOffset = (new Date().getTimezoneOffset() * -1) / 60;
      this.changeTab("1h");
    };
    a();

    this.intervalWorker = setInterval(this.buySellSetup, 2000);
  },
  beforeDestroy() {
    clearInterval(this.intervalWorker);
    window.removeEventListener("resize", this.onWindowResize);
  },
};
</script>
<style scoped>
.container-coin {
  touch-action: pan-y !important;
}
.container-coin div {
  touch-action: pan-y !important;
}
.header-container {
  background-color: #092135 !important;
}
.coin-price-text {
  font-size: 1.4rem;
  color: #737373;
}
.coin-change-text {
  font-size: 1.1rem;
}
.text-price-up {
  color: #30bb87;
}
.text-price-down {
  color: #f7445d;
}
.text-coin-subtitle {
  color: #a6a6a6;
  font-size: 0.8rem;
  white-space: nowrap;
  overflow: hidden;
}
.text-coin-subtitle-value {
  color: #737373;
  white-space: nowrap;
  overflow: hidden;
  font-size: 0.8rem;
}
.chart-tab-container {
  color: #bdbdbd;
  font-size: 0.8rem;
  border-radius: 10px 10px 0 0;
  background-color: #121826;
  padding-top: 0.6rem;
  padding-bottom: 0.2rem;
}
.chart-selected-text {
  color: white;
  font-weight: bold;
  font-size: 0.9rem;
}
.history-title-container {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #cccccc;
  color: #4d4d4d;
  font-size: 0.8rem;
  border-radius: 1rem 1rem 0 0;
  font-size: 1.1rem;
}
.history-title-text {
  color: #4d4d4d;
}
.history-no-text {
  font-size: 0.8rem;
  color: #bfbfbf;
}
.button-login {
  font-size: 1.2rem;
  background-color: #3d406c;
  color: #ffffff;
  padding: 0.4rem;
  border-radius: 0.75rem;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>
