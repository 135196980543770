var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{staticStyle:{"background-color":"white"},attrs:{"fullscreen":"","persistent":"","value":_vm.value},on:{"input":function($event){return _vm.$emit('input')}}},[_c('v-card',{staticStyle:{"height":"100%"}},[_c('div',{staticClass:"container-coin d-flex flex-column",staticStyle:{"background-color":"#f5f5f5","height":"100%"}},[_c('v-row',{staticClass:"flex-shrink-1 px-4",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex my-2"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('input')}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('div',{staticClass:"my-auto ml-4",staticStyle:{"color":"#030303","font-size":"20px"}},[_vm._v(" "+_vm._s(_vm.coin.symbol_head + _vm.coin.symbol_end)+" ")])],1)])],1),_c('div',{staticClass:"flex-grow-0 px-4",staticStyle:{"position":"relative","overflow-y":"scroll"}},[_c('v-row',{staticClass:"mx-2 mt-1",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-1",attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex my-auto"},[_c('div',{staticClass:"coin-price-text my-auto",staticStyle:{"white-space":"nowrap","overflow":"hidden"}},[_vm._v(" "+_vm._s("$")+" "+_vm._s(_vm.coin.is_customized ? _vm.coinInfo.current_price.toPrecision(4) : _vm.coinInfo.current_price)+" ")]),_c('img',{staticClass:"flex-shrink-1 my-auto",staticStyle:{"width":"1.3rem"},attrs:{"src":_vm.coinInfo.change_pct_24h < 0 ? _vm.arrowDownIcon : _vm.arrowUpIcon}})]),_c('div',{class:[
                  'd-flex',
                  'mt-1',
                  'coin-change-text',
                  _vm.coinInfo.change_pct_24h < 0 ? 'text-price-down' : 'text-price-up',
                ]},[_c('div',[_vm._v(" "+_vm._s(_vm.coin.is_customized ? _vm.coinInfo.change_24h.toPrecision(4) : _vm.coinInfo.change_24h)+" ")]),_c('div',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.coin.is_customized ? _vm.coinInfo.change_pct_24h.toPrecision(4) : _vm.coinInfo.change_pct_24h)+_vm._s("%")+" ")])])])]),_c('v-col',{staticClass:"px-4",attrs:{"cols":"6"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"text-coin-subtitle"},[_vm._v(" "+_vm._s(_vm.$t("price.coinDetail.24hHigh"))+" ")]),_c('div',{staticClass:"text-coin-subtitle-value"},[_vm._v(" "+_vm._s(_vm.coinInfo.highest_24h.toPrecision(4))+" ")])])]),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"text-coin-subtitle"},[_vm._v(" "+_vm._s(_vm.$t("price.coinDetail.24hVol"))+" ")]),_c('div',{staticClass:"text-coin-subtitle-value"},[_vm._v(" "+_vm._s(_vm.coinInfo.vol_24h.toPrecision(4))+" ")])])]),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex flex-column mt-1"},[_c('div',{staticClass:"text-coin-subtitle"},[_vm._v(" "+_vm._s(_vm.$t("price.coinDetail.24hLow"))+" ")]),_c('div',{staticClass:"text-coin-subtitle-value"},[_vm._v(" "+_vm._s(_vm.coinInfo.lowest_24h.toPrecision(4))+" ")])])]),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex flex-column mt-1"},[_c('div',{staticClass:"text-coin-subtitle"},[_vm._v(" "+_vm._s(_vm.$t("price.coinDetail.24hVolUSDT"))+" ")]),_c('div',{staticClass:"text-coin-subtitle-value"},[_vm._v(" "+_vm._s((_vm.coinInfo.vol_usd_24h / 1000000).toFixed(1) + "M")+" ")])])])],1)],1)],1),_c('div',{ref:"chartContainer",staticClass:"mt-4"},[_c('v-row',{staticClass:"chart-tab-container",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"2"},on:{"click":function($event){return _vm.changeTab('1m')}}},[_c('div',{class:[
                  'mx-auto',
                  'my-auto',
                  { 'chart-selected-text ': _vm.chartTab == '1m' },
                ]},[_vm._v(" "+_vm._s(_vm.$t("price.coinDetail.1m"))+" ")])]),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"2"},on:{"click":function($event){return _vm.changeTab('15m')}}},[_c('div',{class:[
                  'mx-auto',
                  'my-auto',
                  { 'chart-selected-text ': _vm.chartTab == '15m' },
                ]},[_vm._v(" "+_vm._s(_vm.$t("price.coinDetail.15m"))+" ")])]),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"2"},on:{"click":function($event){return _vm.changeTab('1h')}}},[_c('div',{class:[
                  'mx-auto',
                  'my-auto',
                  { 'chart-selected-text ': _vm.chartTab == '1h' },
                ]},[_vm._v(" "+_vm._s(_vm.$t("price.coinDetail.1h"))+" ")])]),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"2"},on:{"click":function($event){return _vm.changeTab('4h')}}},[_c('div',{class:[
                  'mx-auto',
                  'my-auto',
                  { 'chart-selected-text ': _vm.chartTab === '4h' },
                ]},[_vm._v(" "+_vm._s(_vm.$t("price.coinDetail.4h"))+" ")])]),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"2"},on:{"click":function($event){return _vm.changeTab('1d')}}},[_c('div',{class:[
                  'mx-auto',
                  'my-auto',
                  { 'chart-selected-text ': _vm.chartTab === '1d' },
                ]},[_vm._v(" "+_vm._s(_vm.$t("price.coinDetail.1d"))+" ")])])],1)],1),_c('div',{staticClass:"noselect",staticStyle:{"background-color":"#121826","position":"relative","height":"350px"}},[(_vm.isChartLoading)?_c('v-overlay',{attrs:{"absolute":""}},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1):_c('TradingVue',{attrs:{"timezone":_vm.timezoneOffset,"titleTxt":_vm.coin.symbol_head + _vm.coin.symbol_end,"height":350,"width":_vm.chartWidth,"data":_vm.chartData}})],1),_c('div',{staticClass:"noselect",staticStyle:{"background-color":"#121826","height":"10px","border-radius":"0 0 10px 10px"}}),_c('div',{staticClass:"mt-5 d-flex flex-column"},[_c('div',{staticClass:"history-title-container"},[_vm._v(" "+_vm._s(_vm.$t("price.coinDetail.order"))+" ")]),_c('div',{staticClass:"d-flex mt-1",staticStyle:{"color":"#737373"}},[_c('div',{staticStyle:{"width":"50%"}},[_vm._v(_vm._s(_vm.$t("price.coinDetail.buy")))]),_c('div',{staticStyle:{"width":"50%"}},[_vm._v(_vm._s(_vm.$t("price.coinDetail.sell")))])]),_c('div',{staticClass:"d-flex",staticStyle:{"font-weight":"bold"}},[_c('div',{staticStyle:{"color":"#30bb87"}},[_vm._v(" "+_vm._s(((_vm.buySell[3] * 100) / (_vm.buySell[3] * 1 + _vm.buySell[2] * 1)).toFixed(1))+_vm._s("%")+" ")]),_c('div',{staticClass:"d-flex flex-grow-1 mx-2"},[_c('div',{staticClass:"my-auto",staticStyle:{"background-color":"#30bb87","height":"4px","transition":"width 0.3s"},style:({
                  width:
                    Math.floor((_vm.buySell[3] * 100) / (_vm.buySell[3] * 1 + _vm.buySell[2] * 1)) +
                    '%',
                })}),_c('div',{staticClass:"my-auto",staticStyle:{"background-color":"#f7445d","height":"4px","transition":"width 0.3s"},style:({
                  width:
                    Math.floor(
                      ((_vm.buySell[2] * 100) / (_vm.buySell[3] * 1 + _vm.buySell[2] * 1)) * 1
                    ) + '%',
                })})]),_c('div',{staticStyle:{"color":"#f7445d"}},[_vm._v(" "+_vm._s(((_vm.buySell[2] * 100) / (_vm.buySell[3] * 1 + _vm.buySell[2] * 1)).toFixed(1))+_vm._s("%")+" ")])]),_c('div',{staticClass:"d-flex mt-1",staticStyle:{"color":"#737373"}},[_c('div',{staticClass:"pa-2 d-flex",staticStyle:{"background-color":"#069b40","width":"50%","color":"#ffffff"}},[_c('div',[_vm._v(" "+_vm._s(_vm.coin.symbol_head)+" ")]),_c('div',{staticClass:"ml-2"},[_vm._v("$"+_vm._s(_vm.buySell[1]))]),_c('div',{staticClass:"ml-auto"},[_vm._v(" "+_vm._s(_vm.buySell[3])+" ")])]),_c('div',{staticClass:"pa-2 d-flex",staticStyle:{"background-color":"#db2020","width":"50%","color":"#ffffff"}},[_c('div',[_vm._v(" "+_vm._s(_vm.coin.symbol_head)+" ")]),_c('div',{staticClass:"ml-2"},[_vm._v("$"+_vm._s(_vm.buySell[0]))]),_c('div',{staticClass:"ml-auto"},[_vm._v(" "+_vm._s(_vm.buySell[2])+" ")])])])])],1),_c('div',{staticClass:"flex-shrink-1 px-4",staticStyle:{"background-color":"#e0e0e0"}},[_c('button',{staticClass:"w-full button-login mt-4 mb-8",on:{"click":_vm.tradeButton}},[_vm._v(" "+_vm._s(_vm.$t("price.coinDetail.trade"))+" ")])])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }