<template>
  <v-dialog
    fullscreen
    persistent
    style="background-color: white"
    @input="$emit('input')"
    :value="value"
  >
    <v-card>
      <v-toolbar style="position: sticky; top: 0; z-index: 1000" dark color="#3d406c">
        <v-btn icon @click="$emit('input')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("referral.title") }}</v-toolbar-title>
      </v-toolbar>

      <ReferralInner :referralCode="referralCode" />
    </v-card>
  </v-dialog>
</template>
<script>
import ReferralInner from "./ReferralInner.vue";
export default {
  data() {
    return { tab: 0 };
  },
  props: ["value", "referralCode"],
  components: {
    ReferralInner,
  },
  computed: {},
  mounted() {},
};
</script>
<style scoped></style>
